import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

import type { TCurrencyCode } from '@/infra/types/common';

import type { QuickSearchItems, QuickSearchItemsData } from './types';

import { createQuickSearchItemsRecord, getCacheKey } from './utils';

const quickSearchItemsSlice = createSlice({
  initialState: {} as QuickSearchItemsData,
  name: 'quickSearchItems',
  reducers: {
    addData: (state, { payload }: PayloadAction<{ currencyCode: TCurrencyCode; sailors: number }>) => {
      state.items = {
        ...state.items,
        ...createQuickSearchItemsRecord([], payload.currencyCode, payload.sailors, -1),
      };
    },
    resetData: (state, { payload }: PayloadAction<{ currencyCode: TCurrencyCode; sailors: number }>) => {
      state.items = omit(state.items, [getCacheKey(payload.currencyCode, payload.sailors)]);
    },
    updateData: (
      state,
      { payload }: PayloadAction<{ currencyCode: TCurrencyCode; items: QuickSearchItems; sailors: number }>,
    ) => {
      state.items = {
        ...state.items,
        ...createQuickSearchItemsRecord(payload.items, payload.currencyCode, payload.sailors),
      };
    },
  },
});

export const { addData, resetData, updateData } = quickSearchItemsSlice.actions;
export default quickSearchItemsSlice.reducer;
