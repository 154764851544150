import { createSelector } from '@reduxjs/toolkit';

import { type TUserNotification } from '@/infra/types/user';
import { type RootState } from '@/store';

export const selectProfileRoot = (state: RootState) => state?.profile;

export const selectProfileData = (state: RootState) => selectProfileRoot(state)?.data;

export const selectProfileError = (state: RootState) => selectProfileRoot(state)?.error;

export const selectIsProfileLoading = (state: RootState) => selectProfileRoot(state)?.isLoading;

export const selectProfileEmailNotifications = createSelector(selectProfileData, (profile) => {
  const searchKey: keyof TUserNotification = 'IsOptInForEmail';
  return profile?.userNotifications?.filter((notification) =>
    Object.prototype.hasOwnProperty.call(notification, searchKey),
  );
});
