import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '@/store';

export const selectCommon = (state: RootState) => state?.common;

export const selectResources = createSelector(selectCommon, (common) => common?.resources);

export const selectLookup = createSelector(selectCommon, (common) => common?.lookup);

export const selectDefaultCurrencyCode = createSelector(selectLookup, (lookup) => lookup?.defaultCurrencyCode);
