const VV_EVENTS_ROOT_PREFIX = 'VV';

const MODULES = {
  ACCOUNT: 'account',
  HEADER: 'header',
};

const EVENTS = {
  ADD_PAYMENT_INFO: 'AddPaymentInfo',
  ADD_TO_CART: 'AddToCart',
  ENHANCED_ECOMMERCE: 'enhancedEcommerce',
  GA_ECOMMERCE: 'GAecommerce',
  GTMevent: 'GTMevent',

  INITIATE_CHECKOUT: 'InitiateCheckout',
  SEARCH: 'Search',
  SPAPageView: 'SPAPageView',
  ServerExperiment: 'ServerExperiment',
  VIEW_CONTENT: 'ViewContent',
};

const AFFILIATION = {
  VV_ECOMMERCE: 'Virgin Voyages - Ecommerce',
};

const EVENT_ACTIONS = {
  AUTO_APPLIED: 'auto-applied',
  BUTTON_CLICK: 'button-click',
  CARD_CLICK: 'card-click',
  CHECKBOX_DESELECTED: 'checkbox-deselected',
  CHECKBOX_SELECTED: 'checkbox-selected',
  CLOSE: 'close',
  CURRENCY_CONVERTER_CLICK: 'currency-converter-click',
  DISPLAYED: 'displayed',
  ERROR: 'error',
  FIELD_CLICK: 'field-click',
  FILTER_APPLIED: 'filter-applied',
  FORM_COMPLETION: 'form-completion',
  ICON_CLICK: 'icon-click',
  LINK_CLICK: 'link-click',
  PAY_TYPE_SELECTION: 'pay-type-selection',
  SOCIAL_SHARE: 'social-share',
  TOGGLE_CLICK: 'toggle-click',
};

const MEASURES = {
  ADD_TO_CART: 'measuring-add-to-cart',

  CHECKOUT_STEP_1: 'measuring-checkout-step-1',
  CHECKOUT_STEP_2: 'measuring-checkout-step-2',

  IMPRESSIONS: 'measuring-impressions',
  PRODUCT_CLICKS: 'measuring-product-clicks',

  PRODUCT_DETAILS: 'measuring-product-details',

  PROMOTION_CLICKS: 'measuring-promotion-clicks',
  PROMOTION_VIEWS: 'measuring-promotion-views',

  PURCHASES: 'measuring-purchases',
};

const EVENT_LABELS = {
  ACCESS_KEY: 'access-key',
  ACCESS_KEY_APPLIED: 'access-key-applied',
  BANNER: 'banner',
  CELEBRATE_TOGETHER: 'celebrate-together',
  CHANGE_DATES: 'change-dates',
  CHOOSE_CABIN: 'choose-cabin',
  CONTACT_US: 'contact-us',
  EMAIL: 'email',
  EMAIL_OPT_IN_RADIO_NO: 'no-thank-you',
  EMAIL_OPT_IN_RADIO_YES: 'yes-please-i-want-to-receive-email-updates-from-virgin-voyages',
  FACEBOOK: 'facebook',
  FM_INFO: 'FM-info',
  FM_OPT_OUT: 'FM-opt-out',
  FULL_CRUISE_DETAILS: 'full-cruise-details',
  HERE_IS_HOW_IT_WORKS: 'here-is-how-it-works',
  INFO_ICON: 'info-icon',
  LEADERBOARD_BANNER: 'leaderboard-banner',
  MOBILE_OPT_IN: 'mobile-opt-in',
  NEWSLETTER_SIGNUP: 'newsletter-signup',
  REFERRAL_CODE_LINK: 'referral-code-link',
  SEARCH: 'search',
  TERMS_AND_CONDITIONS: 'terms-and-conditions',
  TWITTER: 'twitter',
  UPLIFT_INFO: 'uplift-info',
  UPSELL_LIGHTBOX: 'upsell-lightbox',
  VOYAGE_FILTERS: {
    DESTINATIONS: 'destinations',
    FILTER_NAMES: {
      CABIN_TYPE: 'cabin-type',
      DEALS_AND_LIMITED_OFFERS: 'deals-and-limited-time-offers',
      DEPARTURE_PORT: 'departure-port',
      NUMBER_OF_NIGHTS: 'number-of-nights',
      PRICE_PER: 'price-per',
      PRICE_RANGE: 'price-range',
      SHIP: 'ship',
      SORT_BY: 'sort-by',
      TRAVEL_PARTY: 'travel-party',
    },
    NEXT_SAILING_DATES: 'cta-next-sailing-dates',
    NEXT_SAILING_ITEM: 'cta-next-sailing-item',
    RESET_FILTER: 'reset-filter',
    SEARCH_AGAIN: 'search-again',
    TO_REFINEMENT_FILTERS: 'to-refinement-filters',
    TRAVEL_DATES: 'travel-dates',
  },
  ZERO_RESULTS: 'zero-results',
};

const FLYOUTS = {
  ACCOUNTS_WITH_BENEFITS: 'accounts-with-benefits-pop-up',
  ADDONS: 'addons-flyout',
  BLACK_FRIDAY: 'black-friday',
  CHANGE_DATES: 'change-dates-flyout',
  ITINERARY_RESULTS: 'itinerary-results-flyout',
  ITINERARY_SAILING_DATES: 'itinerary-sailing-dates-flyout',
  NAVIGATION_HELPER_POPUP: 'navigation-helper-pop-up',
  OFFER: 'offer-flyout',
  REFINEMENT_RESULTS: 'refinement-results',
  SAIL_WITH_CELEBRATIONS: 'sail-with-celebrations-flyout',
  SAIL_WITH_CELEBRATIONS_MODULE: 'sail-with-celebrations-module',
  SAIL_WITH_CELEBRATIONS_MODULE_POPUP: 'sail-with-celebrations-module-pop-up',
  SAIL_WITH_CELEBRATIONS_MODULE_POPUP_X_OUT: 'sail-with-celebrations-x-out-module-pop-up',
  TELL_ME_MORE: 'tell-me-more',
  UPSELL: 'upsell',
  VOYAGE_PROTECTION: 'voyage-protection-flyout',
};

const LISTS = {
  ITINERARY_RESULTS: 'itinerary-results',
  YOUR_VOYAGE: 'your-voyage',
  ZERO_RESULTS: 'zero-results',
};

// Some pages urls do not coincide with names required by GTM tracking,
// so we provide mapping to find GTM tracking name by current location.href
const BOOKING_GTM_MAPPING = {
  'choose-a-cabin': 'choose-cabin',
  dashboard: 'account-dashboard',
  'find-a-voyage': LISTS.ITINERARY_RESULTS,
  fullCruiseDetails: 'itinerary',
  'pre-checkout': LISTS.YOUR_VOYAGE,
  'sailor-details': 'sailor-details',
  'upcoming-voyages': 'account-upcoming-voyages',
};

const BOOKING_GTM_MAPPING_V2 = {
  ...BOOKING_GTM_MAPPING,
  summary: 'voyage-details',
};

const ACCOUNT_GTM_MAPPING = {
  'my-voyages': 'my-voyage',
};

export default {
  ACCOUNT_GTM_MAPPING,
  AFFILIATION,
  BOOKING_GTM_MAPPING,
  BOOKING_GTM_MAPPING_V2,
  EVENT_ACTIONS,
  EVENT_LABELS,
  EVENTS,
  FLYOUTS,
  LISTS,
  MEASURES,
  MODULES,
  VV_EVENTS_ROOT_PREFIX,
};
