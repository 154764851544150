import React, { forwardRef } from 'react';

import type { AppDispatch } from '@/store';
import type { UnknownObject } from '@/types/utils';

import { trackViewOnScroll } from '../event/types/common';
import { withTrackAppearance } from '../hocs';
import observer from '../observer';

type TrackAppearanceBoxInnerProps = {
  children: React.ReactNode;
  className: string;
} & JSX.IntrinsicElements['div'];

const TrackAppearanceBoxInner = (
  { children, className = '', ...attrs }: TrackAppearanceBoxInnerProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) => (
  <div className={`TrackAppearanceBox ${className}`} ref={ref} {...attrs}>
    {children}
  </div>
);

export type TTrackingArg = {
  data: UnknownObject;
  key: string;
};

const onViewed = (trackingArgs: TTrackingArg[], dispatch: AppDispatch) => {
  const { data, key } = trackingArgs?.[0] ?? {};
  const names = observer.TRACKING_EVENTS_FOR_OBSERVER;
  const name = names[key as keyof typeof names];
  if (name) {
    observer.trackingEventsObserver.notify({ data: { ...data, dispatch }, name });
  }
};

const TrackAppearanceBox = withTrackAppearance({
  Component: forwardRef(TrackAppearanceBoxInner),
  componentPropToTrack: 'trackingArgs',
  isOmitPropToTrack: true,
  onAppearanceAction: trackViewOnScroll({ onViewed }),
  oncePerPage: true,
});

export default TrackAppearanceBox;
