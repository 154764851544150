import { combineReducers } from 'redux';

import cabinExhaustError from './cabinExhaustError';
import voyageExhaustError from './voyageExhaustError';
import voyageSearch from './voyageSearch';

export default combineReducers({
  cabinExhaustError,
  voyageExhaustError,
  voyageSearch,
});
