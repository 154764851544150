import constants from '../../constants';
import { trackButtonClick } from './common';

const { MODULES } = constants;

export const trackAccountDropdownOpens = () => {
  const labels = [MODULES.ACCOUNT];

  trackButtonClick({ labels });
};
