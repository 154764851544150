import defaults from 'lodash/defaults';
import isEmpty from 'lodash/isEmpty';

import type { TSailorInfo } from '../types';

import { FIELD_NAMES } from '../constants';

export type TProfileArg = {
  addresses?: Array<{
    city?: string;
    countryCode?: string;
    lineOne?: string;
    lineTwo?: string;
    state?: string;
    zipCode?: string;
  }>;
  birthDate?: string;
  citizenshipCountryCode?: string;
  email?: string;
  firstName?: string;
  genderCode?: string;
  lastName?: string;
  phoneCountryCode?: string;
  phoneNumber?: string;
};

const convertProfileDataToPrimarySailor = (profile: TProfileArg, defaultValues: Partial<TSailorInfo>) =>
  (!isEmpty(profile)
    ? defaults(
        {
          [FIELD_NAMES.APT_NO]: profile.addresses?.[0]?.lineTwo,
          [FIELD_NAMES.CITIZENSHIP]: profile.citizenshipCountryCode,
          [FIELD_NAMES.CITY]: profile.addresses?.[0]?.city,
          [FIELD_NAMES.COUNTRY]: profile.addresses?.[0]?.countryCode,
          [FIELD_NAMES.DOB]: profile.birthDate,
          [FIELD_NAMES.EMAIL]: profile.email,
          [FIELD_NAMES.FIRST_NAME]: profile.firstName,
          [FIELD_NAMES.GENDER]: profile.genderCode,
          [FIELD_NAMES.LAST_NAME]: profile.lastName,
          [FIELD_NAMES.PHONE]: profile.phoneNumber,
          [FIELD_NAMES.PHONE_COUNTRY_CODE]: profile.phoneCountryCode,
          [FIELD_NAMES.STATE]: profile.addresses?.[0]?.state,
          [FIELD_NAMES.STREET_ADDRESS]: profile.addresses?.[0]?.lineOne,
          [FIELD_NAMES.ZIP_CODE]: profile.addresses?.[0]?.zipCode,
        },
        defaultValues,
      )
    : defaultValues) as TSailorInfo;

export default convertProfileDataToPrimarySailor;
