import type { TCurrencyCode } from '@/infra/types/common';

import { selectCurrencyCode } from '@/ducks/filters/selectors';
import { selectInvoiceCurrencyCode } from '@/ducks/pages/summary/selectors';
import { getSessionStorageValue, setSessionStorageValue } from '@/helpers/util';
// import { getVWOValue } from '@/helpers/vwo/getVWOValue';
// import getInstance from '@/helpers/vwo/instance';
// import { useVWOFlag } from '@/hooks/useVWOFeature';
import { FILTER_KEY } from '@/ducks/filters/types';
import { getSearchParam } from '@/ducks/routes/history';
import { useAppSelector } from '@/store';
import { type VWOFeatureFlags, VWOFlag } from '@/types/vwo';

export const useGetIsTaxIncluded = () => {
  const filterCurrencyCode = useAppSelector(selectCurrencyCode);
  const invoiceCurrencyCode = useAppSelector(selectInvoiceCurrencyCode);
  const isTaxesAndFeesFeatureEnabled = getIsTaxesIncludedFromStorage(); // useVWOFlag(VWOFlag.TAXES_AND_FEES);
  return appointTaxInclusiveByCurrencyCode(invoiceCurrencyCode || filterCurrencyCode, isTaxesAndFeesFeatureEnabled);
};

export const getIsTaxesIncluded = () => {
  // TODO: replace using storage, and searchparams as params
  const isTaxesAndFeesFeatureEnabled = getIsTaxesIncludedFromStorage(); // getVWOValue({ key: VWOFlag.TAXES_AND_FEES, vwoClient: await getInstance() });
  const currencyCode = getSearchParam(FILTER_KEY.currencyCode);
  return appointTaxInclusiveByCurrencyCode(currencyCode, isTaxesAndFeesFeatureEnabled);
};

const VWO_FLAG_SESSION_STORAGE_KEY = 'isTaxInclusive';
const DEFAULT_VALUE = true;

const getIsTaxesIncludedFromStorage = () => getSessionStorageValue(VWO_FLAG_SESSION_STORAGE_KEY) || DEFAULT_VALUE;
export const saveIsTaxesIncludedToStorage = (featuresFlags: VWOFeatureFlags) => {
  const value = featuresFlags[VWOFlag.TAXES_AND_FEES] ?? DEFAULT_VALUE;

  setSessionStorageValue(VWO_FLAG_SESSION_STORAGE_KEY, value);
};

const appointTaxInclusiveByCurrencyCode = (currencyCode: TCurrencyCode, defaultValue = false) => {
  return ['AUD', 'GBP', 'NZD'].includes(currencyCode) || defaultValue;
};
