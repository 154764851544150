export const defaultDataState = {
  data: {},
  error: {},
};

export const defaultDataLoadingState = {
  data: {},
  error: {},
  loading: false,
};
