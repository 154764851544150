export const enum ThunkState {
  PENDING = 'pending',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}

const getThunkStates = (prefix: string) => ({
  [ThunkState.FULFILLED]: `${prefix}/${ThunkState.FULFILLED}`,
  [ThunkState.PENDING]: `${prefix}/${ThunkState.PENDING}`,
  [ThunkState.REJECTED]: `${prefix}/${ThunkState.REJECTED}`,
});

export default getThunkStates;
