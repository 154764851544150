import React from 'react';

const withTrackPopupState = ({
  Component,
  onCloseModalProp = 'onCloseModal',
  onToggleState,
  toggleStateProp = 'isOpen',
}) => {
  class TrackPopupStateWrapper extends React.Component {
    onCloseModalTracked = (...props) => {
      onToggleState(false);
      this.props?.[onCloseModalProp]?.(...props);
    };

    componentDidMount() {
      const isModalOpened = this.props?.[toggleStateProp];

      if (isModalOpened) {
        onToggleState(true);
      }
    }

    componentDidUpdate(prevProps) {
      const isModalOpened = !prevProps?.[toggleStateProp] && this.props?.[toggleStateProp];
      if (isModalOpened) {
        onToggleState(true);
      }
    }

    render() {
      const trackedProps = {
        ...this.props,
        [onCloseModalProp]: this.onCloseModalTracked,
      };
      return <Component {...trackedProps} />;
    }
  }

  TrackPopupStateWrapper.displayName = `TrackPopupStateWrapper(${Component.name})`;

  return TrackPopupStateWrapper;
};

export default withTrackPopupState;
