import type { AppDispatch, AppGetState } from '@/store';
import type { TOptional } from '@/types/common';

import { selectConfigServiceFormValidations } from '@/ducks/common/settings/selectors';
import { selectSailingData } from '@/ducks/voyageInfo/sailing/selectors';

import type { TFieldName, TSailorInvalidity } from './types';

import { FIELD_NAMES } from './constants';
import getAdditionalSailorRequiredFields from './helpers/getAdditionalSailorRequiredFields';
import makeValidationOptions from './helpers/makeValidationOptions';
import {
  makePrimarySailorsExcludedFieldsSelector,
  selectAdditionalSailorExcludedFieldsFullForm,
  selectAdditionalSailorExcludedFieldsSameAddress,
  selectCountriesWithProvinces,
  selectIsVoyageProtectionAdded,
  selectPrimarySailor,
  selectPrimarySailorErrors,
  selectPrimarySailorRequiredFields,
  selectSecondarySailors,
  selectUsedEmails,
} from './selectors';
import { ensurePrimarySailorFieldError, getAllPrimarySailorErrors, validateAllFields } from './validators';

export type TValidateFieldOptions = {
  invalidTLDs?: TOptional<string[]>;
  isEmailOptInRadioEnabled?: TOptional<boolean>;
  isMNVV?: TOptional<boolean>;
  isMobileOptInEnabled?: TOptional<boolean>;
};

export const validatePrimarySailorField =
  (
    name: TFieldName,
    value: string,
    { invalidTLDs, isEmailOptInRadioEnabled, isMNVV, isMobileOptInEnabled }: TValidateFieldOptions,
  ) =>
  (_: AppDispatch, getState: AppGetState): TFieldName[] => {
    const state = getState();
    const requiredFields = selectPrimarySailorRequiredFields(state);
    const primarySailor = selectPrimarySailor(state);
    const invalidFields = selectPrimarySailorErrors(state);
    const fieldPatterns = selectConfigServiceFormValidations(state);
    const selectPrimarySailorsExcludedFields = makePrimarySailorsExcludedFieldsSelector(
      isMobileOptInEnabled,
      isMNVV,
      isEmailOptInRadioEnabled,
    );
    const excludedFields = selectPrimarySailorsExcludedFields(state);
    const usedEmails = selectUsedEmails(state);
    const sailingData = selectSailingData(state);
    const options = makeValidationOptions({ invalidTLDs, sailingData, sailor: primarySailor, usedEmails });
    return ensurePrimarySailorFieldError({
      excludedFields,
      fieldPatterns,
      invalidFields,
      name,
      options,
      requiredFields,
      value,
    });
  };

export const validatePrimarySailor =
  (
    invalidTLDs: TOptional<string[]>,
    isMobileOptInEnabled: boolean,
    isMNVV: boolean,
    isEmailOptInRadioEnabled: boolean,
  ) =>
  (_: AppDispatch, getState: AppGetState): TOptional<TFieldName[]> => {
    const state = getState();
    const requiredFields = selectPrimarySailorRequiredFields(state);
    const excludedFields = makePrimarySailorsExcludedFieldsSelector(
      isMobileOptInEnabled,
      isMNVV,
      isEmailOptInRadioEnabled,
    )(state);
    const fieldPatterns = selectConfigServiceFormValidations(state);
    const sailingData = selectSailingData(state);
    const values = selectPrimarySailor(state);
    const usedEmails = selectUsedEmails(state);
    const options = makeValidationOptions({ invalidTLDs, sailingData, sailor: values, usedEmails });
    if (values) {
      return getAllPrimarySailorErrors({ excludedFields, fieldPatterns, options, requiredFields, values });
    }
  };

export const validateSecondarySailors =
  (invalidTLDs: TOptional<string[]>) =>
  (_: AppDispatch, getState: AppGetState): TSailorInvalidity[] => {
    const state = getState();
    const secondarySailors = selectSecondarySailors(state);
    const excludedFieldsFullForm = selectAdditionalSailorExcludedFieldsFullForm(state);
    const excludedFieldsSameAddress = selectAdditionalSailorExcludedFieldsSameAddress(state);
    const countriesWithProvinces = selectCountriesWithProvinces(state);
    const isVoyageProtected = selectIsVoyageProtectionAdded(state);
    const fieldPatterns = selectConfigServiceFormValidations(state);
    const sailingData = selectSailingData(state);
    const usedEmails = selectUsedEmails(state);
    return (secondarySailors || [])
      .map((sailor): TOptional<TSailorInvalidity> => {
        const isSameAsPrimary = sailor?.[FIELD_NAMES.IS_SAME_AS_PRIMARY];
        const excludedFields = isSameAsPrimary ? excludedFieldsSameAddress : excludedFieldsFullForm;
        const requiredFields = getAdditionalSailorRequiredFields({
          countriesWithProvinces,
          countryCode: sailor?.[FIELD_NAMES.COUNTRY],
          isSameAsPrimary,
          isVoyageProtected,
        });
        const options = makeValidationOptions({ invalidTLDs, sailingData, sailor, usedEmails });
        const errorFields = validateAllFields({
          excludedFields,
          fieldPatterns,
          options,
          requiredFields,
          values: sailor,
        });
        const isNotValid = errorFields?.length;
        if (isNotValid) {
          return {
            errorFields,
            sailorNumber: sailor?.sailorNumber,
          } as TSailorInvalidity;
        }
        return undefined;
      })
      .filter(Boolean) as TSailorInvalidity[];
  };
