export const MGM_VALIDATE_REFERRAL_CODE_SUCCESS = 'MGM_VALIDATE_REFERRAL_CODE_SUCCESS';
export const MGM_VALIDATE_REFERRAL_CODE_FAILURE = 'MGM_VALIDATE_REFERRAL_CODE_FAILURE';
export const MGM_EXIT_REFERRED_BOOKER_FLOW = 'MGM_EXIT_REFERRED_BOOKER_FLOW';
export const MGM_GENERATE_REFERRAL_URL_START = 'MGM_GENERATE_REFERRAL_URL_START';
export const MGM_GENERATE_REFERRAL_URL_SUCCESS = 'MGM_GENERATE_REFERRAL_URL_SUCCESS';
export const MGM_GENERATE_REFERRAL_URL_FAILURE = 'MGM_GENERATE_REFERRAL_URL_FAILURE';
export const MGM_LINK_REFERRAL_CODE_SUCCESS = 'MGM_LINK_REFERRAL_CODE_SUCCESS';
export const MGM_LINK_REFERRAL_CODE_ERROR = 'MGM_LINK_REFERRAL_CODE_ERROR';
export const MGM_CLEAR_DATA = 'MGM_CLEAR_DATA';
export const MGM_CLEBERATION_CASE_SHOWERROR = 'MGM_CLEBERATION_CASE_SHOWERROR';
export const MGM_CLEBERATION_CASE_NOERROR = 'MGM_CLEBERATION_CASE_NOERROR';
