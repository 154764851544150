import { type CaseReducer, type PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { TStrikethroughData } from '@/features/strikethrough/types';
import type { TStoreLoadablePartition } from '@/infra/types/common';

export type TStoreStrikethrough = TStoreLoadablePartition<TStrikethroughData>;

export const strikethroughDataNull = {
  externalId: '',
  name: '',
  strikeThroughAddons: [],
  strikethroughEnabled: false,
} as TStrikethroughData;

export const initialState: TStoreStrikethrough = {
  data: strikethroughDataNull,
  error: [],
  isLoaded: undefined,
  isLoading: undefined,
};

const setAllCase: CaseReducer<TStoreStrikethrough, PayloadAction<TStoreStrikethrough>> = (_, { payload }) => payload;

const setDataCase: CaseReducer<TStoreStrikethrough, PayloadAction<TStrikethroughData>> = (stateDraft, { payload }) => {
  stateDraft.data = payload || initialState;
};

const setErrorCase: CaseReducer<TStoreStrikethrough, PayloadAction<unknown>> = (stateDraft, { payload }) => {
  stateDraft.error = payload;
};

const { actions, reducer } = createSlice({
  initialState,
  name: 'strikethrough',
  reducers: {
    setAll: setAllCase,
    setData: setDataCase,
    setError: setErrorCase,
  },
});

export const { setAll, setData, setError } = actions;

export default reducer;
