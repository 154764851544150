import { JL } from 'jsnlog';
import isEmpty from 'lodash/isEmpty';
import isObjectLike from 'lodash/isObjectLike';
import isString from 'lodash/isString';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import qs from 'query-string';

import getCurrentSearchParams from '@/helpers/url/getCurrentSearchParams';
import searchParamsAsObject from '@/helpers/url/searchParamsAsObject';

let nextRouter = null;
let nextPathname = null;
let nextSearchParams = null;

export const setRouter = (router) => {
  nextRouter = router;
};
export const setPathname = (pathname) => {
  nextPathname = pathname;
};
export const setSearchParams = (searchParams) => {
  nextSearchParams = searchParams;
};

export const getRouter = () => {
  // console.log('getRouter', nextRouter);
  return nextRouter;
};
export const getPathname = () => {
  // console.log('getPathname', nextPathname);
  return nextPathname;
};
export const getURLSearchParams = () => {
  // console.log('getURLSearchParams', nextSearchParams);
  return nextSearchParams;
};

/**
 * @deprecated use `getCurrentSearchParams` instead
 */
export const getSearchParams = (options) => {
  // console.log('getSearchParams', nextSearchParams);
  return qs.parse(nextSearchParams?.toString(), options);
};
export const getSearchParam = (paramName) => {
  // console.log('getSearchParam', nextSearchParams.get(paramName));
  return nextSearchParams?.get(paramName);
};

export const matches = (path) => nextPathname.includes(path);

export const buildUrlPath = ({ hash, pathname = '', query }) => {
  const hashPath = hash ? `#${hash}` : '';
  const searchStr = query ? qs.stringify(query) : '';
  const searchPath = searchStr ? `?${searchStr}` : '';
  const urlPath = `${pathname}${searchPath}${hashPath}`;

  return urlPath;
};

const redirectToExternal = ({ hash, pathname, query }) => {
  const urlPath = buildUrlPath({ hash, pathname, query });

  if (!urlPath) {
    console.warn('Invalid external url to redirect', urlPath);
    return;
  }

  window.location.href = urlPath;
};

export const redirect = (
  pathname,
  params = {},
  { isHref = false, keepSearch = false, replace = false, routerArgs = {} } = {},
  hashLocation = '',
) => {
  const redirectParams = {
    hash: hashLocation,
    pathname,
    query: keepSearch
      ? {
          ...getSearchParams(),
          ...params,
        }
      : params,
  };

  if (isHref) {
    redirectToExternal(redirectParams);
    return;
  }

  const messageDetail = {
    message: 'redirect called',
    messageCode: '5001',
    severity: 'INFO',
  };
  JL('BV').info({
    message: redirectParams,
    messageDetail,
  });

  const urlPath = buildUrlPath(redirectParams);

  if (replace) {
    nextRouter.replace(urlPath, routerArgs);
  } else {
    nextRouter.push(urlPath, routerArgs);
  }
};

/**
 * Change current search query without changing pathname with history.replace()
 */
export const updateSearch = (query, { nextjs = {}, nprogress = {} } = {}) => {
  const compactQuery = clearEmptyParams(query);

  // TODO: remove this when we have a better solution
  const newQueryString = new URLSearchParams(compactQuery).toString();
  const currentQueryString = getCurrentSearchParams().toString();
  if (newQueryString !== currentQueryString) {
    return nextRouter.replace('?' + newQueryString, { ...nextjs, scroll: false }, nprogress);
  }
};

export const updateSearchHistory = ({ search, url }) => {
  const urlSource = url ?? window.location.href;
  const newUrl = new URL(urlSource);

  if (search) {
    const compactQuery = clearEmptyParams(search);
    const queryString = qs.stringify(compactQuery);
    newUrl.search = new URLSearchParams(queryString);
  }

  window.history.replaceState(null, '', decodeURIComponent(newUrl.toString()));
};

export const getSearchHistory = () => qs.parse(window.location.search); // ONLY, if nextjs have a stale searchParams

const clearEmptyParams = (query) =>
  omitBy(query, (value) => (isObjectLike(value) || isString(value)) && isEmpty(value));

/**
 * Replace specified param in search query with Router.replace()
 */
export const replaceSearchParam = (name, value, force) => {
  if (force || nextSearchParams.get(name)) {
    const messageDetail = {
      message: 'replace called',
      messageCode: '5001',
      severity: 'INFO',
    };
    JL('BV').info({
      message: { name, value },
      messageDetail,
    });

    updateSearch({ ...getSearchParams(), [name]: value });
  }
};

/**
 * Remove particular params from search query with history.replace()
 */
export const removeParticularSearchParams = (paramNames) => {
  return updateSearch(omit(searchParamsAsObject(getCurrentSearchParams()), paramNames));
};

export const removeSearchParams = () => {
  return updateSearch({});
};

export const removeSearchParamsExcept = (paramNames) => {
  return updateSearch(pick(searchParamsAsObject(getCurrentSearchParams()), paramNames));
};
