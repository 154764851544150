import { useCallback } from 'react';

import type { TWithOnClick } from '@/features/promoBanners/types';
import type { TLink } from '@/infra/types/common';

type UseOnClickLabel<T> = {
  onClick: TWithOnClick<T>['onClick'];
  trackEvent: (labels: string[]) => void;
};

// take "label" prop from onClick to pass it trackEvent
export const useOnClickLabel = <T>({ onClick, trackEvent }: UseOnClickLabel<T>) => {
  const trackedOnClick = useCallback(
    (props: Partial<TLink>) => {
      onClick?.(props);
      trackEvent?.(props?.label as string[]);
    },
    [onClick],
  );

  return trackedOnClick;
};
