import { isServerSide } from '@/helpers/isServerSide';

export const isLocalStorageAvailable = (() => {
  if (isServerSide()) {
    return false;
  }
  if (window.localStorage) {
    try {
      localStorage.removeItem('setItem');
      localStorage.setItem('test', 'test');
      localStorage.removeItem('test');
      return true;
    } catch (e) {
      return false;
    }
  } else {
    return false;
  }
})();

export const isSessionStorageAvailable = (() => {
  if (isServerSide()) {
    return false;
  }
  if (window.sessionStorage) {
    try {
      const key = '__session-storage-check';

      sessionStorage.setItem(key, '');
      sessionStorage.removeItem(key);

      return true;
    } catch (e) {
      return false;
    }
  }

  return false;
})();

export const getStoredValue = (name) => {
  if (isLocalStorageAvailable) {
    try {
      return localStorage.getItem(name) ? JSON.parse(localStorage.getItem(name)) : null;
    } catch (error) {
      return null;
    }
  }
  return null;
};

export const setStoredValue = (name, value) => {
  if (isLocalStorageAvailable) {
    localStorage.removeItem('setItem');
    localStorage.setItem(name, JSON.stringify(value));
  }
};

export const removeStoredValue = (name) => {
  if (isLocalStorageAvailable) {
    localStorage.removeItem(name);
  }
};

export const getSessionStorageValue = (key, { parseJson = true } = {}) => {
  if (isSessionStorageAvailable) {
    let rawValue = null;
    try {
      rawValue = sessionStorage.getItem(key);

      if (parseJson) {
        return JSON.parse(rawValue);
      } else {
        return rawValue;
      }
    } catch (err) {
      return null;
    }
  }

  return null;
};

export const setSessionStorageValue = (key, value) => {
  if (isSessionStorageAvailable) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
};

export const removeSessionStorageValue = (key) => {
  if (isSessionStorageAvailable) {
    sessionStorage.removeItem(key);
  }
};

export const callOncePerSession = (sessionKey, identifier, callback, ...args) => {
  const performedIds = JSON.parse(sessionStorage.getItem(sessionKey)) || [];
  if (identifier && performedIds.includes(identifier)) {
    return;
  }
  callback(...args);
  if (identifier) {
    sessionStorage.setItem(sessionKey, JSON.stringify([...performedIds, identifier]));
  }
};
