import type { List } from 'ts-toolbelt';

import { transform } from 'lodash';
import isObject from 'lodash/isObject';
import kebabCase from 'lodash/kebabCase';
import omit from 'lodash/omit';

import { env } from '@/environment';

import { redirect, removeParticularSearchParams, updateSearch } from './history';

const getUrlAccountV1 = (path: string) => `/book/accounts/${path}`;
const getUrlAccountV2 = (path: string) => `/accounts/v1/${path}`;

/**
 * Application routes structure.
 * if the route is plain string, then its value is used as pathname chunk
 * if the route is an object, then its pathname chunk is taken from its .path property, or calculated as kebabCase(<routeName>).
 *
 * This structure gets augmented and normalized by makeRoutes function which constructs absolute pathnames in place of pathname chunks,
 * and adds go() methods for redirects.
 *
 * Usage in routing:
 * routes.planner.chooseCabin.category.path // '<context>/voyage-planner/choose-a-cabin/mega-rockstar'
 * routes.accounts.signin.social.path // <context>/accounts/signin/social-media
 *
 * Usage in redirects:
 * routes.accounts.dashboard.go(); // equal to history.push('<context>/accounts/dashboard');
 * routes.planner.chooseCabin.go({ voyageId: 5, shipCode: 'SC' })
 * // equal to history.push('<context>/voyage=planner/choose-a-cabin?voyageId=5&shipCode=SC')
 *
 * Root search() and searchWithout() are used to change current search string:
 * routes.go({ sailors: 2, cabins: 1 }) // equal to history.replace({ search: '?sailors=2&cabins=1' });
 * routes.goWithout(['selectedRegion']) // equal to history.replace({ search: '...' }) with 'selectedRegion' removed from search string
 *
 * routes.page404.go() redirects to page-not-found
 */
export const paths = {
  accounts: {
    accessKeys: {
      fullPath: getUrlAccountV2('account/access-keys'),
      oldFullPath: getUrlAccountV1('accessKeys'),
    },
    appleLoginButton: {
      fullPath: getUrlAccountV2('account/signin'),
      oldFullPath: getUrlAccountV1('apple-login-button'),
    },
    dashboard: {
      fullPath: getUrlAccountV2('account/dashboard'),
      oldFullPath: getUrlAccountV1('dashboard'),
    },
    mnvv: { fullPath: getUrlAccountV2('mnvv'), oldFullPath: getUrlAccountV1('mnvv') },
    oldFullPath: getUrlAccountV1(''),
    profile: {
      fullPath: getUrlAccountV2('account/personal-information'),
      oldFullPath: getUrlAccountV1('profile-and-settings'),
    },
    reservationDashboard: {
      fullPath: getUrlAccountV2('My-Voyage/my-voyage'),
      oldFullPath: getUrlAccountV1('reservation-dashboard'),
    },
    reset: {
      emailForm: {
        fullPath: getUrlAccountV2('account/reset/email-form'),
        oldFullPath: getUrlAccountV1('reset/email-form'),
      },
      emailSuccess: 'email-success',
      resetLinkExpired: 'reset-link-expired',
      resetPassword: 'reset-password',
      resetPasswordSuccess: {
        fullPath: getUrlAccountV2('account/reset/set-password-success'),
        oldFullPath: getUrlAccountV1('reset/reset-password-success'),
      },
    },
    signin: {
      fullPath: getUrlAccountV2('account/signin'),
      oldFullPath: getUrlAccountV1('signin'),
      social: {
        fullPath: getUrlAccountV2('account/signin'),
        oldFullPath: getUrlAccountV1('signin/social-media'),
      },
    },
    signup: {
      email: {
        fullPath: getUrlAccountV2('account/signup'),
        oldFullPath: getUrlAccountV1('signup/email'),
        social: {
          fullPath: getUrlAccountV2('account/signup'),
          oldFullPath: getUrlAccountV1('signup/email/social-media'),
        },
      },
      fullPath: getUrlAccountV2('account/signup'),
      oldFullPath: getUrlAccountV1('signup'),
      options: {
        fullPath: getUrlAccountV2('account/signup'),
        oldFullPath: getUrlAccountV1('signup/options'),
        social: {
          fullPath: getUrlAccountV2('account/signup'),
          oldFullPath: getUrlAccountV1('signup/options/social-media'),
        },
      },
    },
    upcomingVoyages: {
      fullPath: getUrlAccountV2('account/my-voyages'),
      oldFullPath: getUrlAccountV1('upcoming-voyages'),
    },
    verification: 'verify-email',
  },
  auth: {
    signin: {
      fullPath: 'auth/v1/signin',
    },
  },
  mnvv: {
    confirmation: 'confirmation',
    payment: 'payment',
    sailorDetails: 'sailor-details',
  },
  planner: {
    chooseCabin: {
      category: ':cabinCategoryCode',
      path: 'choose-a-cabin',
    },
    chooseVoyage: {
      path: 'find-a-voyage',
    },
    confirmation: 'confirmation',
    dnsError: {
      path: 'do-not-sail-error',
    },

    fullCruiseDetails: {
      path: 'fullCruiseDetails',
    },
    path: 'voyage-planner',
    payment: {
      connect: 'connect',
    },
    preCheckOut: {
      path: 'pre-checkout',
    },
    sailorDetails: 'sailor-details',
    summary: 'summary',
  },
} as const;

export type PathLeaf = {
  go: (...args: Partial<List.Drop<Parameters<typeof prepareForRedirect>, 1>>) => void;
  oldPath?: string;
  path: string;
  replace: (...args: Partial<List.Drop<Parameters<typeof prepareForRedirect>, 1>>) => void;
};

type Paths<T> = {
  [key in keyof T]: PathLeaf & Paths<T[key]>;
};

type Route = { fullPath: string; path?: never } | { fullPath?: never; path: string };
type RoutesSchema = Record<string, unknown> | string;

/**
 * Recursively augment routes structure for each node:
 * - change or add .path property equal to its absolute pathname
 * - add go() method which programmatically redirects to corresponding route
 */
const makeRoutes = <T extends RoutesSchema>(routesSchema: T, pathname = '') => {
  const result: PathLeaf = {
    go: (...args) => {
      const { hash, options, params, path } = prepareForRedirect(pathname, ...args);
      redirect(path, params, options, hash);
    },
    replace: (...args) => {
      const { hash, options, params, path } = prepareForRedirect(pathname, ...args);
      redirect(path, params, { ...options, replace: true }, hash);
    },
    ...(routesSchema?.oldFullPath ? { oldPath: routesSchema.oldFullPath as string } : undefined),
    path: pathname,
  };

  if (!isObject(routesSchema)) {
    return result;
  }

  return transform(
    routesSchema,
    (acc, route, key) => {
      if (blacklistedKeys.includes(key)) {
        return acc;
      }

      let newPath;
      if (isObject(route)) {
        const { fullPath, path } = route as Route;
        newPath = fullPath ?? `${pathname}/${path || kebabCase(key)}`;
      } else {
        newPath = `${pathname}/${route}`;
      }

      acc[key] = makeRoutes(route as RoutesSchema, newPath);
    },
    result as unknown as Record<string, RoutesSchema>,
  );
};

const prepareForRedirect = (
  pathname: string,
  params: { keepSearch?: boolean; routerArgs?: { scroll?: boolean } } & Record<string, unknown> = {},
  hashLocation = '',
  pathNameParams: Record<string, string> = {},
) => {
  const pathNameParamsKeys = Object.keys(pathNameParams);
  let path = pathname;

  if (pathNameParamsKeys.length) {
    pathNameParamsKeys.forEach((key) => {
      path = path.replace(`:${key}`, kebabCase(pathNameParams[key]));
    });
  }

  return {
    hash: hashLocation,
    options: { keepSearch: params.keepSearch, routerArgs: params.routerArgs },
    params: omit(params, ['keepSearch', 'routerArgs']),
    path,
  };
};

const blacklistedKeys = ['path', 'go', 'replace', 'goHref', 'fullPath', 'oldFullPath'];

export const routes = {
  ...(makeRoutes(paths) as Paths<typeof paths>),
  page404: {
    go: () => redirect(routes.page404.path, undefined, { isHref: true }),
    get path() {
      return env.PAGE_404 || '/page-not-found';
    },
  },
  search: updateSearch,
  searchWithout: removeParticularSearchParams,
};
