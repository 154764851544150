import isEmpty from 'lodash/isEmpty';

import type { RootState } from '@/store';

import createMemoSelector from '@/helpers/createMemoSelector';
import { getPriceBreakdownProps } from '@/helpers/data/mappers/Summary';

const emptyObj = {};

// Direct selectors:

export const selectSummary = (state: RootState) => state?.voyagePlanner?.summary;

export const selectCabinHold = (state: RootState) => selectSummary(state)?.cabinHold;

export const selectIsPayInFullPromoApplied = (state: RootState) =>
  selectSummary(state)?.isPayInFullPromoApplied || false;

export const selectCalculateInvoice = (state: RootState) => selectSummary(state)?.calculateInvoice;
export const selectCalculateInvoiceData = (state: RootState) => selectCalculateInvoice(state)?.data || emptyObj;
export const selectCalculateInvoiceError = (state: RootState) => selectCalculateInvoice(state)?.error ?? emptyObj;
export const selectCalculateInvoiceErrorCabinCategoryCode = (state: RootState) =>
  selectCalculateInvoiceError(state)?.cabinCategoryCode || null;
export const selectCalculateInvoiceIsLoading = (state: RootState) =>
  selectCalculateInvoice(state)?.isLoading || isEmpty(selectCalculateInvoiceData(state));

export const selectIsCalculateInvoiceLoaded = (state: RootState) => selectCalculateInvoice(state)?.isLoaded;

export const selectCabinInvoices = (state: RootState) => selectCalculateInvoiceData(state)?.cabinInvoices;

export const selectAddOnPriceDetails = (state: RootState) => selectCabinInvoices(state)?.[0]?.addOnPriceDetails;

export const selectBookingPriceDetails = (state: RootState) => selectCabinInvoices(state)?.[0]?.bookingPriceDetails;

export const selectInvoiceCurrencyCode = (state: RootState) => selectBookingPriceDetails(state)?.currencyCode;

export const selectCalculateInvoiceCabinNumber = (state: RootState) => selectCabinInvoices(state)?.[0]?.cabinNumber;

export const selectGuestCount = (state: RootState) => selectCabinInvoices(state)?.[0]?.guestCount;

export const selectFullPayPrice = (state: RootState) => selectCabinInvoices(state)?.[0]?.fullPayPrice;

export const selectPaymentSchedule = (state: RootState) => selectCalculateInvoiceData(state)?.paymentSchedule;

export const selectFutureVoyageCreditData = (state: RootState) => selectSummary(state)?.FutureVoyageCredit?.data;

export const selectCabinInvoiceCabinType = (state: RootState) => selectCabinInvoices(state)?.[0]?.cabinTypeCode;

export const selectFutureVoyageCreditIsLoaded = (state: RootState) =>
  selectSummary(state)?.FutureVoyageCredit?.isLoaded;
// Memoized selectors:

export const selectPriceBreakdown = createMemoSelector(selectCalculateInvoiceData, (invoice) =>
  getPriceBreakdownProps(invoice?.cabinInvoices, invoice?.paymentSchedule),
);
