import type { TISODate, TOptional } from '@/types/common';

import type { TSailorInfo, TValidationOptions } from '../types';

import { FIELD_NAMES } from '../constants';

export type TSailingDataSlice = {
  embarkDate: TISODate;
};

export type TMakeValidationOptionsArgs = {
  invalidTLDs: TOptional<string[]>;
  sailingData?: TOptional<TSailingDataSlice>;
  sailor?: TOptional<TSailorInfo>;
  sailorNumber?: TOptional<number>;
  usedEmails?: TOptional<Record<number, string>>;
};

const makeValidationOptions = ({
  invalidTLDs,
  sailingData,
  sailor,
  sailorNumber,
  usedEmails,
}: TMakeValidationOptionsArgs): TValidationOptions => ({
  country: sailor?.[FIELD_NAMES.COUNTRY],
  embarkDate: sailingData?.embarkDate,
  invalidTLDs,
  sailorNumber: sailorNumber ?? sailor?.sailorNumber,
  usedEmails,
});

export default makeValidationOptions;
