import {
  CLEAR_FUTURE_VOYAGE_CREDITS_DETAILS,
  FETCH_FUTURE_VOYAGE_CREDITS_DETAILS_ERROR,
  FETCH_FUTURE_VOYAGE_CREDITS_DETAILS_SUCCESS,
} from '@/constants/actionTypes';

export default function FutureVoyageCreditReducer(store = { data: [], error: {}, isLoaded: false }, action) {
  switch (action.type) {
    case FETCH_FUTURE_VOYAGE_CREDITS_DETAILS_SUCCESS:
      return { data: action.payload, error: {}, isLoaded: true };
    case FETCH_FUTURE_VOYAGE_CREDITS_DETAILS_ERROR:
      return { data: [], error: action.payload, isLoaded: false };
    case CLEAR_FUTURE_VOYAGE_CREDITS_DETAILS:
      return { data: [], error: {}, isLoaded: false };
    default:
      return store;
  }
}
