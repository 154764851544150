import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';

import type { TLookupShip } from '@/ducks/common/lookup/types';
import type { Package } from '@/infra/types/voyageInfo/package';
import type { Port } from '@/infra/types/voyageInfo/port';
import type { SailingFromPackages } from '@/infra/types/voyageInfo/sailing';

import { checkForValueType, ensureArray } from '@/helpers/util';

export const getPackagesForFilteredSailings = (filteredSailings: SailingFromPackages[], packages: Package[]) => {
  const groupedSailings: Record<string, SailingFromPackages[]> = groupBy(uniqBy(filteredSailings, 'id'), 'packageCode');

  return packages.reduce<Package[]>(
    (packages, pckg) =>
      groupedSailings[pckg.packageCode]
        ? [...packages, { ...pckg, sailingList: groupedSailings[pckg.packageCode]! }]
        : packages,
    [],
  );
};

export const getSailingsFromPackages = (packages: Package[]) => {
  if (!checkForValueType(packages)) {
    return [];
  }

  const sailings: SailingFromPackages[] = [];
  packages.forEach((pckg) => {
    pckg.sailingList.forEach((sailing) => {
      sailings.push(sailing);
    });
  });

  return sailings;
};

export const filterShips = (packages: Package[], shipList: TLookupShip[]) => {
  if (isEmpty(shipList) || shipList === undefined) {
    return packages;
  }
  const sailings = getSailingsFromPackages(packages);
  if (!checkForValueType(sailings)) {
    return packages;
  }

  const filteredSailings = sailings.filter((s) => shipList.some((ship) => s.ship.code === ship?.id));
  return getPackagesForFilteredSailings(filteredSailings, packages);
};

export const filterDeparturePorts = (packages: Package[], homePorts: Port[]) => {
  if (isEmpty(homePorts) || homePorts === undefined) {
    return packages;
  }
  const finalpackages = packages?.filter((s) => homePorts?.some((port) => s?.departurePortCode === port?.code));
  return finalpackages;
};

export const filterByPromoVoyages = (voyages: string[], packages: Package[]) => {
  if (isEmpty(packages) || isEmpty(voyages)) {
    return packages;
  }

  const filteredPackages = packages.reduce<Package[]>((packages, pack) => {
    const sailings = pack.sailingList.filter((sailing) =>
      ensureArray(voyages)?.some((voyageId) => voyageId === sailing?.id),
    );
    return !isEmpty(sailings)
      ? [
          ...packages,
          {
            ...pack,
            sailingList: sailings,
          },
        ]
      : packages;
  }, []);

  return filteredPackages;
};
