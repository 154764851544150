import { PROMOTION_VOYAGES_DATA_ERROR, PROMOTION_VOYAGES_DATA_SUCCESS } from '@/constants/actionTypes';

export default function PromotionVoyagesReducer(store = { data: {}, error: {} }, action) {
  switch (action.type) {
    case PROMOTION_VOYAGES_DATA_SUCCESS:
      return { ...store, data: action.payload, error: {} };
    case PROMOTION_VOYAGES_DATA_ERROR:
      return { ...store, data: {}, error: action.payload };
    default:
      return store;
  }
}
