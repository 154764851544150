import { type CaseReducer, type PayloadAction, createSlice } from '@reduxjs/toolkit';

import { type TPaymentPaidAmount } from '@/infra/types/payment';

export const initialState = [] as TPaymentPaidAmount[];

const setCase: CaseReducer<TPaymentPaidAmount[], PayloadAction<TPaymentPaidAmount[]>> = (_, { payload }) => payload;

const appendCase: CaseReducer<TPaymentPaidAmount[], PayloadAction<TPaymentPaidAmount>> = (stateDraft, { payload }) => [
  ...stateDraft,
  payload,
];

const clearCase: CaseReducer<TPaymentPaidAmount[], PayloadAction<undefined>> = () => [...initialState];

const { actions, reducer } = createSlice({
  initialState,
  name: 'payment/paidAmounts',
  reducers: {
    appendPaidAmount: appendCase,
    clearPaidAmounts: clearCase,
    setPaidAmounts: setCase,
  },
});

export const { appendPaidAmount, clearPaidAmounts, setPaidAmounts } = actions;

export default reducer;
