import { combineReducers } from 'redux';

import { BOOK_NOW_ENCRYPTED_RESERVATION_SUCCESS } from '@/constants/actionTypes';

import bookNow from './bookNow';
import paymentOption from './paymentOption';
import signatureResponse from './signatureResponse';

export function encReservationNumber(store = { data: '', error: {} }, action) {
  switch (action.type) {
    case BOOK_NOW_ENCRYPTED_RESERVATION_SUCCESS:
      return { ...store, data: action.payload, error: {} };
    default:
      return store;
  }
}

export default combineReducers({
  bookNow,
  encReservationNumber,
  paymentOption,
  signatureResponse,
});
