import { cloneElement, isValidElement } from 'react';
import type { ReactElement } from 'react';

import type { TPrimitive, TUIResourceNode, TUIResourceValue } from '@/ducks/common/resources/types';

const safeJoin = (parts: TUIResourceValue[], keyPrefix: string): TUIResourceNode | string => {
  if (!parts?.length) {
    return '';
  }
  type TResult = ReactElement | string;
  const results: TResult[] = [];
  const buffer: TPrimitive[] = [];
  for (const part of parts) {
    if (typeof part === 'object' && isValidElement(part)) {
      // If the item is a react element - add it to the results, having previously drop the buffer there
      if (buffer.length) {
        results.push(buffer.join(''));
        buffer.splice(0, buffer.length);
      }
      results.push(part);
    } else {
      // Otherwise, add the item to the buffer
      buffer.push(part as TPrimitive);
    }
  }
  if (buffer.length) {
    // Drop the remaining buffer into results
    results.push(buffer.join(''));
    buffer.splice(0, buffer.length);
  }
  // If only one element turned out - return it directly
  if (results.length === 1) {
    return results[0] as TResult;
  }
  // Otherwise, need to add keys to react elements
  return results.map((item: ReactElement | string, i) =>
    typeof item === 'string' ? item : cloneElement(item, { key: `${keyPrefix}/${i + 1}` }),
  );
};

export default safeJoin;
