import { HTTP_API_ERRORS_CLEAR } from '@/constants/actionTypes';

export default function httpApiErrorsReducer(httpErrors = [], action) {
  const isHttpError = action?.payload?.isAxiosError || action?.payload?.message === 'Network Error';
  const isHttpErrorsClear = action?.type === HTTP_API_ERRORS_CLEAR;

  if (isHttpErrorsClear) {
    return [];
  }

  if (isHttpError) {
    return [...httpErrors, { ...action }];
  }

  return httpErrors;
}
