import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

type Cabin = unknown; // type me

type State = {
  cabin: Cabin;
  error: Error | null;
};

const initialState: State = {
  cabin: null,
  error: null,
};

const selectedCabinSlice = createSlice({
  initialState,
  name: 'voyageInfo/selected-cabin',
  reducers: {
    clearSelectedCabin: () => {
      return initialState;
    },
    setSelectedCabin: (state, { payload: { cabin } }: PayloadAction<{ cabin: Cabin }>) => {
      state.cabin = cabin;
    },
    setSelectedCabinError: (state, { payload: { error } }: PayloadAction<{ error: Error }>) => {
      state.error = error;
    },
  },
});

export const { clearSelectedCabin, setSelectedCabin, setSelectedCabinError } = selectedCabinSlice.actions;
export const selectedCabinReducer = selectedCabinSlice.reducer;
