import { combineReducers } from 'redux';

import chooseCabin from './chooseCabin';
import confirmation from './confirmation';
import itineraryResults from './itineraryResults';
import payment from './payment';
import sailorDetails from './sailorDetails';
// eslint-disable-next-line import/no-cycle
import summary from './summary';
import whatsIncluded from './whatsIncluded';

export default combineReducers({
  chooseCabin,
  confirmation,
  itineraryResults,
  payment,
  sailorDetails,
  summary,
  whatsIncluded,
});
