import { SUMMARY_GET_NEW_CABIN_ERROR, SUMMARY_GET_NEW_CABIN_SUCCESS } from '@/constants/actionTypes';

export default function newCabinByAttributesReducer(store = { data: {}, error: {} }, action) {
  switch (action.type) {
    case SUMMARY_GET_NEW_CABIN_SUCCESS: //eslint-disable-line
      return {
        ...store,
        data: action.payload,
        error: {},
      };
    case SUMMARY_GET_NEW_CABIN_ERROR:
      return { ...store, data: {}, error: action.payload };
    default:
      return store;
  }
}
