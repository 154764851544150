import { addYears } from 'date-fns';

import {
  MNVV_AGENCY_ID_KEY,
  MNVV_AGENT_ID_KEY,
  MNVV_CHANNEL_ID_KEY,
  MNVV_OFFER_LAUNCH_DATE,
  MNVV_OFFER_VALIDITY_IN_YEARS,
  MNVV_RESERVATION_NUMBER_KEY,
} from './constants';

export const calculateMaxDate = (inputDate?: string) => {
  const date = new Date(inputDate || MNVV_OFFER_LAUNCH_DATE);
  return addYears(date, MNVV_OFFER_VALIDITY_IN_YEARS);
};

export const getMnvvReservation = (searchParams: URLSearchParams) => {
  const agencyId = searchParams.get(MNVV_AGENCY_ID_KEY);
  const agentId = searchParams.get(MNVV_AGENT_ID_KEY);
  const channelID = searchParams.get(MNVV_CHANNEL_ID_KEY);
  const reservationNumber = searchParams.get(MNVV_RESERVATION_NUMBER_KEY);

  return {
    agencyId,
    agentId,
    channelID,
    isMNVV: !!(channelID && reservationNumber),
    reservationNumber,
  };
};
