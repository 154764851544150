import constants from '@/tagmanager/constants';
import builders from '@/tagmanager/event/builders';

type Url = string;
type UrlSubParts = string[];

const mapUrls = (urls: Url[], map: Record<Url, Url>) => {
  if (!map) {
    return urls;
  }

  const mappedUrls = urls.map((url) => map[url] ?? url);

  return mappedUrls;
};

const noDuplicateWithModuleName = (urls: Url[], module: string) => {
  if (!urls?.length) {
    return urls;
  }
  const withoutDuplicatedModule = urls?.[0] === module ? urls.slice(1) : urls;
  return withoutDuplicatedModule;
};

const NOT_LAST_SIGNIFICANT_PATHS_LIST = [constants.BOOKING_GTM_MAPPING_V2['choose-a-cabin']];
const takeSignificantSubPath = (urlSubParts: UrlSubParts) => {
  if (!urlSubParts || urlSubParts.filter(Boolean)?.length === 0) return urlSubParts;

  const isNotLastSignificantIndex = urlSubParts.findIndex((arr) => NOT_LAST_SIGNIFICANT_PATHS_LIST.includes(arr));
  if (isNotLastSignificantIndex === -1) return urlSubParts.at(-1);

  return builders.buildWithDelimiter(urlSubParts.slice(isNotLastSignificantIndex));
};

export default { mapUrls, noDuplicateWithModuleName, takeSignificantSubPath };
