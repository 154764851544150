import { getPathname } from '@/ducks/routes/history';
import { env } from '@/environment';

import constants from '../../constants';
import { trackEvent } from '../../dataLayer';

const isBlacklisted = (url) => url?.includes('/[[...');

const makeUrlPrepared = (url) => `${env.CONTEXT}${(isBlacklisted(url) ? getPathname() : url)?.split('?')?.[0]}`;

export const trackPageOpen = (url) => {
  const preparedUrl = makeUrlPrepared(url);

  const trackableEvent = {
    event: constants.EVENTS.SPAPageView,
    url: preparedUrl,
  };
  trackEvent(trackableEvent);
};
