export default class Observer {
  notify = (event) => {
    this.observers.forEach((observer) => {
      if (observer.name !== event.name) {
        return;
      }

      observer?.handler?.(event.data);
    });
  }; // { name: enum, handler: function }[]

  observers = [];

  subscribe = (watcher) => {
    const isExist = this.observers.includes(watcher);
    if (isExist) {
      console.log('Handler has been already subscribed');
      return;
    }

    this.observers.push(watcher);
  };

  unsubscribe = (watcher) => {
    const observerIndex = this.observers.indexOf(watcher);
    if (observerIndex === -1) {
      console.warn('No such handlers');
      return;
    }

    this.observers.splice(observerIndex, 1);
  };
}
