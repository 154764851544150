import concat from 'lodash/concat';

import type { VoyagesDataResponse } from '@/infra/types/voyageInfo/package';

export const calculateAdditonalVoyagesData = (voyagesData: VoyagesDataResponse) => ({
  mapPackageCodeToRegionIds: voyagesData.regions.reduce<Record<string, string[]>>((result, region) => {
    const packageCodeToRegionId = region.packages.reduce<Record<string, string[]>>((ids, { id }) => {
      if (id && ids[id]) {
        return { ...ids, [id]: [...ids[id]!, region.id] };
      }
      return id ? { ...ids, [id]: [region.id] } : ids;
    }, result);
    return {
      ...result,
      ...packageCodeToRegionId,
    };
  }, {}),
  mapPortOfCallCodeToRegionIds: voyagesData.regions.reduce<Record<string, string[]>>((result, region) => {
    const portCodeToRegionId = concat(...Object.values(region.portsOfCall)).reduce<Record<string, string[]>>(
      (codes, { code }) => {
        if (code && codes[code]) {
          return { ...codes, [code]: [...codes[code]!, region.id] };
        }

        return code ? { ...codes, [code]: [region.id] } : codes;
      },
      result,
    );

    return {
      ...result,
      ...portCodeToRegionId,
    };
  }, {}),
});
