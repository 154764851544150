import type { TCurrencyCode } from '@/infra/types/common';
import type { Voyage } from '@/infra/types/voyageInfo/voyage';

export enum MnvvTypes {
  ExtendGlow = 'XE500103MNVV',
  LastingGlow = 'XE500106MNVV',
  MNVV = 'XE500101MNVV',
  Tiered = 'XE500201MNVV',
}

export type MnvvContent = {
  discounts: Record<string, { amount: string; currencyCode: TCurrencyCode }>;
};

export type MnvvData = {
  channelID?: null | string;
  content?: MnvvContent;
  voyage?: Voyage;
};
