import { type DeepPartial, configureStore } from '@reduxjs/toolkit';
import { type TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import type { TOptional } from '@/types';

import { isProdEnv } from '@/helpers/util/env';

import rootReducer from './reducer';

export const setupStore = (preloadedState?: DeepPartial<RootState>, options: { reconfigure?: boolean } = {}) => {
  if (rootStore == null || options.reconfigure) {
    rootStore = configureStore({
      devTools: !isProdEnv() && { name: 'VV Book', trace: true, traceLimit: 25 },
      // @ts-expect-error add proper typings
      middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          immutableCheck: {
            // TODO: MSH-97146 Remove after filters refactoring
            ignoredPaths: ['filters.packages'],
          },
        }),
      // @ts-expect-error add proper typings
      preloadedState,
      reducer: rootReducer,
    });
  }
  return rootStore;
};

export let rootStore: TOptional<AppStore> = undefined;

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof configureStore<RootState>>;
export type AppDispatch = AppStore['dispatch'];
export type AppGetState = AppStore['getState'];

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
