import { type CaseReducer, type PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { TPromoBannerAny } from '@/features/promoBanners/types';
import type { TStoreLoadablePartition } from '@/infra/types/common';

export type TStorePromoBanners = TStoreLoadablePartition<TPromoBannerAny[]>;

export const initialState: TStorePromoBanners = {
  data: [],
  error: undefined,
  isLoaded: undefined,
  isLoading: undefined,
};

const setAllCase: CaseReducer<TStorePromoBanners, PayloadAction<TStorePromoBanners>> = (_, { payload }) => payload;

const setDataCase: CaseReducer<TStorePromoBanners, PayloadAction<TPromoBannerAny[]>> = (stateDraft, { payload }) => {
  stateDraft.data = payload || [];
};

const setErrorCase: CaseReducer<TStorePromoBanners, PayloadAction<unknown>> = (stateDraft, { payload }) => {
  stateDraft.error = payload;
};

const { actions, reducer } = createSlice({
  initialState,
  name: 'promoBanners',
  reducers: {
    setAll: setAllCase,
    setData: setDataCase,
    setError: setErrorCase,
  },
});

export const { setAll, setData, setError } = actions;

export default reducer;
