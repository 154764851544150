import { getSearchParam } from '@/ducks/routes/history';

import { routes } from './routes';
import {
  ChooseCabinMetaSearchParams,
  ChooseCabinSubMetaSearchParams,
  calculateSearchParams,
  searchParamsModifierDefaultArgument,
} from './searchParams';

const chooseCabinSubMetaFilterKeys = () => [...Object.values(ChooseCabinSubMetaSearchParams)];
const chooseCabinMetaFilterKeys = () => [...chooseCabinSubMetaFilterKeys()];
const chooseVoyageFilterKeys = () => [...chooseCabinMetaFilterKeys(), ...Object.values(ChooseCabinMetaSearchParams)];

export const navigateToSummary = (searchParamsModifier = searchParamsModifierDefaultArgument()) => {
  routes.planner.summary.go(calculateSearchParams(searchParamsModifier));
};

export const navigateToChooseCabinSubMeta = (
  cabinCategoryCode,
  searchParamsModifier = searchParamsModifierDefaultArgument(),
) => {
  const code = cabinCategoryCode || getSearchParam(ChooseCabinSubMetaSearchParams.cabinCategoryCode);

  if (!code) {
    navigateToChooseCabinMeta();
  }

  routes.planner.chooseCabin.category.go(
    calculateSearchParams(searchParamsModifier, chooseCabinSubMetaFilterKeys()),
    '',
    { cabinCategoryCode: code },
  );
};

export const navigateToChooseCabinMeta = (searchParamsModifier = searchParamsModifierDefaultArgument()) => {
  routes.planner.chooseCabin.go(calculateSearchParams(searchParamsModifier, chooseCabinMetaFilterKeys()));
};

export const navigateToChooseVoyage = (searchParamsModifier = searchParamsModifierDefaultArgument()) => {
  const calculatedSearchParams = calculateSearchParams(searchParamsModifier, [
    ...chooseVoyageFilterKeys(),
    'charterId',
  ]);
  routes.planner.chooseVoyage.go(calculatedSearchParams);
};

export const navigateTo404Page = () => routes.page404.go();
