import kebabCase from 'lodash/kebabCase';

import { trackButtonClick, trackLinkClick, trackPopupState, trackSocialShare } from '@/tagmanager/event/types/common';

import constants from '../../constants';
import { createTracker } from '../../tracker';

let trackEvent = createTracker({
  event: constants.EVENTS.GTMevent,
});

const SOCIAL_SHARE_POSITIONS = {
  BOTTOM: 'bottom',
  TOP: 'top',
};

const REGION_FULLNAME_MAP = {
  // temp fix, until backend cannot be changed - https://virginvoyages.atlassian.net/browse/MSH-97067
  CARIB: 'CARIBBEAN',
  SOUTH: 'SOUTH PACIFIC',
  TRANSA: 'TRANSATLANTIC',
};

const correlateFullAndShortRegion = (selectedPackage) => {
  const fullNameFound = Object.keys(REGION_FULLNAME_MAP).find((shortName) =>
    selectedPackage?.region?.includes(shortName),
  );

  return fullNameFound ? REGION_FULLNAME_MAP[fullNameFound] : selectedPackage?.region;
};

const initTracker = (selectedPackage) => {
  const region = correlateFullAndShortRegion(selectedPackage);
  const moduleName = selectedPackage ? `${kebabCase(region)}|${kebabCase(selectedPackage?.packageName)}` : null;
  trackEvent = createTracker({
    event: constants.EVENTS.GTMevent,
    module: moduleName,
  });
};

const trackOnBackClick = (label) => {
  trackLinkClick({
    eventEmitter: trackEvent,
    labels: [label],
  });
};

const trackOnShareClick = (platform, position) => {
  trackSocialShare({
    eventEmitter: trackEvent,
    labels: [platform, position],
  });
};

const trackOnPortClick = (port) => {
  trackLinkClick({
    eventEmitter: trackEvent,
    labels: [port],
  });
};

const trackNextSailingClick = () => {
  trackLinkClick({
    eventEmitter: trackEvent,
    labels: [constants.EVENT_LABELS.VOYAGE_FILTERS.NEXT_SAILING_ITEM],
  });
};

const trackCabinTypeClick = (cabinType) => {
  const transformedLabel = kebabCase(cabinType);
  trackEvent({
    action: constants.EVENT_ACTIONS.CARD_CLICK,
    label: transformedLabel,
  });
};

const trackOpenViewMoreDates = (label) => {
  trackButtonClick({
    eventEmitter: trackEvent,
    labels: [label?.join('')],
  });
};

const trackCloseViewMoreDates = () =>
  trackPopupState({
    eventEmitter: trackEvent,
    flyouts: [constants.FLYOUTS.ITINERARY_SAILING_DATES],
    isOpen: false,
  });

const trackFullCruiseClick = (args) =>
  trackButtonClick({
    eventEmitter: trackEvent,
    labels: [constants.EVENT_LABELS.FULL_CRUISE_DETAILS],
    ...args,
  });

const trackNextSailingDateClick = () => {
  trackLinkClick({
    eventEmitter: trackEvent,
    flyouts: [constants.FLYOUTS.ITINERARY_SAILING_DATES],
    labels: [constants.EVENT_LABELS.VOYAGE_FILTERS.NEXT_SAILING_DATES],
  });
};

const trackChangeDatesClick = () => {
  trackLinkClick({
    eventEmitter: trackEvent,
    labels: [constants.EVENT_LABELS.CHANGE_DATES],
  });
};

export default {
  REGION_FULLNAME_MAP,
  SOCIAL_SHARE_POSITIONS,
  initTracker,
  trackCabinTypeClick,
  trackChangeDatesClick,
  trackCloseViewMoreDates,
  trackFullCruiseClick,
  trackNextSailingClick,
  trackNextSailingDateClick,
  trackOnBackClick,
  trackOnPortClick,
  trackOnShareClick,
  trackOpenViewMoreDates,
};
