/* eslint-disable max-len */

export default [
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'AF',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'AX',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'AL',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'DZ',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'AS',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'AD',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'AO',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'AI',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'AQ',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'AG',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'AR',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'AM',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'AW',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressSuburb',
    countryCode: 'AU',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'AT',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'AZ',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'BS',
    stateLabel: 'InsuranceAddress.addressIsland',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'BH',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'BD',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'BB',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'BY',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'BE',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'BZ',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'BJ',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'BM',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'BT',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'BO',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'BA',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'BW',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'BV',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'BR',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'IO',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'VG',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'BN',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'BG',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'BF',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'BI',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'KH',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'CM',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'CA',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'CV',
    stateLabel: 'InsuranceAddress.addressIsland',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'KY',
    stateLabel: 'InsuranceAddress.addressIsland',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'CF',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'TD',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'CL',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'CN',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'CX',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'CC',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'CO',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'KM',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'CG',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'CD',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'CK',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'CR',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'CU',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'HR',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'CW',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'CY',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'CZ',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'DK',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'DJ',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'DM',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'DO',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'EC',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'EG',
    stateLabel: 'InsuranceAddress.addressGovernorate',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'SV',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'GQ',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'ER',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'EE',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'ET',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'FK',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'FO',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'FJ',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'FI',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'FR',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'GF',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'PF',
    stateLabel: 'InsuranceAddress.addressIsland',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'TF',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'GA',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'GM',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'GE',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'DE',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'GH',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'GI',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'GR',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'GL',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'GD',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'GP',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'GU',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'GT',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'GG',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'GN',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'GW',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'GY',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'HT',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'HM',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'HN',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressDistrict',
    countryCode: 'HK',
    stateLabel: 'InsuranceAddress.addressArea',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'HU',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'IS',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'IN',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPinCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressRegency',
    countryCode: 'ID',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'IR',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'IQ',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressTown',
    countryCode: 'IE',
    stateLabel: 'InsuranceAddress.addressCounty',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'IM',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'IL',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'IT',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'CI',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'JM',
    stateLabel: 'InsuranceAddress.addressParish',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'JP',
    stateLabel: 'InsuranceAddress.addressPrefecture',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'JE',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'JO',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'KZ',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'KE',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'KI',
    stateLabel: 'InsuranceAddress.addressIsland',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'KW',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'KG',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'LA',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'LV',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'LB',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'LS',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'LR',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'LY',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'LI',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'LT',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'LU',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'MO',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'MK',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'MG',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'MW',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'MY',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'MV',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'ML',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'MT',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'MH',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'MQ',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'MR',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'MU',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'YT',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'MX',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'FM',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'MD',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'MC',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'MN',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'ME',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'MS',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'MA',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'MZ',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'MM',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'NA',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'NR',
    stateLabel: 'InsuranceAddress.addressDistrict',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'NP',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'NL',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'AN',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'NC',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressTown',
    countryCode: 'NZ',
    stateLabel: 'InsuranceAddress.addressRegion',
    zipCodeLabel: 'InsuranceAddress.addressPostCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'NI',
    stateLabel: 'InsuranceAddress.addressDepartment',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'NE',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'NG',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'NU',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'NF',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'MP',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'KP',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'NO',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'OM',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'PK',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'PW',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'PS',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'PA',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'PG',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'PY',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressDistrict',
    countryCode: 'PE',
    stateLabel: 'InsuranceAddress.addressRegion',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'PH',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'PN',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'PL',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'PT',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'PR',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'QA',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'RE',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'RO',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'RU',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'RW',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'BL',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'SH',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'KN',
    stateLabel: 'InsuranceAddress.addressIsland',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'LC',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'MF',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'PM',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'VC',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'WS',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'SM',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'ST',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'SA',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'SN',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'RS',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'SC',
    stateLabel: 'InsuranceAddress.addressIsland',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'SL',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'SG',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'SK',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'SI',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'SB',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'SO',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'ZA',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'GS',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'KR',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'ES',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'LK',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'SD',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'SR',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'SJ',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'SZ',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'SE',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'CH',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'SY',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'TW',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'TJ',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'TZ',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'TH',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'TL',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'TG',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'TK',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'TO',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'TT',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'TN',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'TR',
    stateLabel: 'InsuranceAddress.addressProvince',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'TM',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'TC',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'TV',
    stateLabel: 'InsuranceAddress.addressIsland',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'VI',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'UG',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'UA',
    stateLabel: 'InsuranceAddress.addressRegion',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'AE',
    stateLabel: 'InsuranceAddress.addressEmirate',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressTown',
    countryCode: 'GB',
    stateLabel: 'InsuranceAddress.addressCounty',
    zipCodeLabel: 'InsuranceAddress.addressPostCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'US',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'UM',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'UY',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'UZ',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'VU',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'VA',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'VE',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'VN',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'WF',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'EH',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'YE',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'ZM',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressPostalCode',
  },
  {
    cityLabel: 'InsuranceAddress.addressCity',
    countryCode: 'ZW',
    stateLabel: 'InsuranceAddress.addressState',
    zipCodeLabel: 'InsuranceAddress.addressZipCode',
  },
];
