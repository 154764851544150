import { v4 as uuid } from 'uuid';

import { VWO_USER_ID_STORAGE_KEY } from '@/constants/vwoConstants';
import { getSessionStorageValue, getStoredValue, setSessionStorageValue } from '@/helpers/util/storage';

export const getVWOUserId = () => {
  const vwoUserId = getSessionStorageValue(VWO_USER_ID_STORAGE_KEY);

  if (vwoUserId == null) {
    const { userId: authUserId } = getStoredValue('Authorization') ?? {};
    const userId = authUserId ?? uuid();
    setSessionStorageValue(VWO_USER_ID_STORAGE_KEY, userId);
    return userId;
  }

  return vwoUserId;
};
