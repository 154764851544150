import { SET_TRAVEL_INSURANCE_DATA_FAILURE, SET_TRAVEL_INSURANCE_DATA_SUCCESS } from '@/constants/actionTypes';

export default function travelInsuranceFlyoutData(store = { data: {}, error: {} }, action) {
  switch (action.type) {
    case SET_TRAVEL_INSURANCE_DATA_SUCCESS:
      return { ...store, data: action.payload, error: {} };
    case SET_TRAVEL_INSURANCE_DATA_FAILURE:
      return { ...store, data: {}, error: action.payload };
    default:
      return store;
  }
}
