/**
 * Message formatting rules
 *
 * All fragments enclosed in curly brackets are processed patterns. Any text outside such patterns is returned unchanged.
 *
 * When processing fragments inside curly brackets , the following patterns are recognized:
 * - The entire text is the name of the field in the "values" arguments object. In which case the pattern is replaced by the argument value
 *     message: '... {A} ...'
 *     values: { A: 'some value' }
 *     result: '... some value ...'
 *     message: '... {A/B/C} ...'
 *     values: { 'A/B/C': 'some value' }
 *     result: '... some value ...'
 *     message: '... {片段} ...'
 *     values: { '片段': 'some value' }
 *     result: '... some value ...'
 * - The text has a special form of a "plural" pattern. In this case, it is filled in according to the value from the "values" arguments object, whose name is specified in the pattern
 *     message: '... {N, plural, one {{single}} other {{many}}} ...'
 *     values: { N: 1 }
 *     result: '... single ...'
 *     values: { N: 3 }
 *     result: '... many ...'
 *     values: { N: 3, many: 'very much' }
 *     result: '... very much ...'
 * - The short form of the "plural" pattern. Processed similarly to the full form
 *     message: '... {N} {single/many} ...'
 *     values: { N: 1 }
 *     result: '... single ...'
 *     values: { N: 3 }
 *     result: '... many ...'
 *     values: { N: 3, many: 'very much' }
 *     result: '... very much ...'
 * - The text is a list separated by the symbol "/". In this case, one list item is selected according to the value from the "values" arguments object
 *     message: '... {one/two/three} ...'
 *     values: { two: true }
 *     result: '... two ...'
 *     values: { two: 'second' }
 *     result: '... second ...'
 *
 * The "parseCmsTemplate" function has an "errorPolicy" argument with valid values of "leave-as-is", "empty" or "throw". If the value "throw" is used, an error is thrown if it is impossible to fill the template. If the "empty" value is used, then unsuccessful template will return as empty string. If the "leave-as-is" value is used (the default value), then unsuccessful template remains unfilled.
 */

import { ErrorPolicy, type TUIResourceValue, type TUIResourceValueMap } from '@/ducks/common/resources/types';

export const MARK_OPEN = '#<##<<###';
export const MARK_CLOSE = '###>>##>#';

const parseCmsTemplate = (
  template: string,
  values: TUIResourceValueMap,
  errorPolicy?: ErrorPolicy,
): TUIResourceValue[] => {
  // Not a string - return as is
  if (typeof template !== 'string') {
    return template;
  }
  // Plain string, not a template - return itself as one array item
  if (!template || !template.includes('{')) {
    return [template];
  }

  const hasSubTemplates = /{[^{}]+{{/.test(template);
  const options = { lastName: undefined };
  const fragments = (hasSubTemplates ? template.replace(/{{([^{}]*)}}/g, `${MARK_OPEN}$1${MARK_CLOSE}`) : template)
    .split(/({[^{}]*})/g)
    .filter(Boolean);
  return fragments.map((fragment: string) => {
    const { length } = fragment;
    if (fragment.charAt(0) === '{' && fragment.charAt(length - 1) === '}') {
      const { error, result } = parseCmsTemplateFragment(fragment.substring(1, length - 1), values, options);
      if (error) {
        switch (errorPolicy) {
          case ErrorPolicy.THROW:
            throw new TypeError(error);
          case ErrorPolicy.EMPTY:
            return '';
          // case ErrorPolicy.LEAVE_AS_IS:
          default:
            return revert(fragment);
        }
      }
      return result as TUIResourceValue;
    }
    if (fragment.trim()) {
      options.lastName = undefined;
    }
    return fragment;
  });
};

type TOptions = { lastName: string | undefined };
type TResult = { error?: string; result?: TUIResourceValue };

export const parseCmsTemplateFragment = (fragment: string, values: TUIResourceValueMap, options: TOptions): TResult => {
  if (fragment in values) {
    // The entire text is the name of the field in the "values" arguments object
    options.lastName = fragment;
    return { result: values[fragment] };
  }
  if (fragment.includes(' plural,')) {
    // The text has a special form of a "plural" pattern (in full form)
    options.lastName = undefined;
    const expressions = fragment
      .split(new RegExp(`, +plural, +one +${MARK_OPEN}|${MARK_CLOSE} +other +${MARK_OPEN}|${MARK_CLOSE} *$`, 'g'))
      .filter(Boolean);
    if (expressions.length === 3) {
      const [name, one, other] = expressions as [string, string, string];
      if (name in values) {
        const { [name]: value } = values;
        if (typeof value === 'number') {
          return { result: selectByNumeric(value, values, one, other) };
        }
      }
      return { error: `Missed required value: ${name}` };
    }
    return { error: `Wrong plural syntax: ${revert(fragment)}` };
  }
  if (fragment.includes('/')) {
    const expressions = fragment.split(/\//g);
    if (expressions.every(Boolean)) {
      const { lastName } = options;
      if (expressions.length === 2 && lastName) {
        // The short form of the "plural" pattern
        options.lastName = undefined;
        const [one, other] = expressions as [string, string];
        const { [lastName]: value } = values;
        if (typeof value === 'number') {
          return { result: selectByNumeric(value, values, one, other) };
        }
      }
      // The text is a list separated by the symbol "/"
      if (expressions.some((expression) => expression in values)) {
        const name = expressions.find((expression) => values[expression]);
        if (name) {
          const { [name]: value } = values;
          return { result: value === true ? name : value };
        }
      }
    }
  }
  return { error: `Missed value or unknown syntax: ${revert(fragment)}` };
};

export const selectByNumeric = (numeric: number, values: TUIResourceValueMap, one: string, other: string) =>
  (numeric === 1 ? (one in values ? values[one] : one) : other in values ? values[other] : other) as TUIResourceValue;

export const revert = (fragment: string): string =>
  fragment.replace(new RegExp(MARK_OPEN, 'g'), '{{').replace(new RegExp(MARK_CLOSE, 'g'), '}}');

export default parseCmsTemplate;
