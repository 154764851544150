import type { RootState } from '@/store';

import { selectCalculateInvoiceData, selectIsPayInFullPromoApplied } from '../selectors';

export const selectTripInsuranceAmount = (state: RootState) => {
  const calculateInvoice = selectCalculateInvoiceData(state);
  const isPayInFullPromoApplied = selectIsPayInFullPromoApplied(state);
  const priceObjectKey = isPayInFullPromoApplied ? 'fullPayPrice' : 'bookingPriceDetails';

  return calculateInvoice?.cabinInvoices?.reduce((sum, cabin) => sum + (cabin[priceObjectKey]?.tripInsurance ?? 0), 0);
};
