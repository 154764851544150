import { type PayloadAction, createReducer } from '@reduxjs/toolkit';

import type { FilteredPackage, GenericCategoryCode, PackagesData } from '@/infra/types/voyageInfo/package';
import type { SailingFromPackages } from '@/infra/types/voyageInfo/sailing';

import { groupPackagesWithSameName } from '@/ducks/pages/chooseVoyage/groupPackagesWithSameNames';

import {
  NEW_CHOOSE_VOYAGE_ADD_TRACKED_SAILINGS,
  NEW_CHOOSE_VOYAGE_SEARCH_API_SUCCESS,
  NEW_CHOOSE_VOYAGE_SEARCH_FAILURE,
  NEW_CHOOSE_VOYAGE_SEARCH_START,
  NEW_CHOOSE_VOYAGE_SEARCH_SUCCESS,
  NEW_CHOOSE_VOYAGE_SET_LOADING,
  NEW_GENERIC_CATEGORY_CODE_DATA,
} from './actionTypes';

type State = {
  defaultGenericCategoryCodes: GenericCategoryCode[];
  error: boolean;
  filteredPackages: {
    isLoaded: boolean;
    packages: FilteredPackage[];
    sailings: SailingFromPackages[];
  };
  genericCategoryCodes: GenericCategoryCode[];
  isLoading: boolean | null;
  isVoyageInvalid: boolean;
  mainPackages: {
    extendedPackages: FilteredPackage[];
    isLoaded: boolean;
  } & Pick<PackagesData, 'defaultPackages' | 'fetchPayload' | 'packages' | 'sailings'>;
  trackedSailings: string[];
};

const defaultState: State = {
  defaultGenericCategoryCodes: [],
  error: false,
  // filtered packages array after filter selection
  filteredPackages: { isLoaded: false, packages: [], sailings: [] },
  genericCategoryCodes: [],
  isLoading: null,
  isVoyageInvalid: false,
  mainPackages: {
    defaultPackages: [],
    extendedPackages: [],
    fetchPayload: null,
    isLoaded: false,
    packages: [],
    sailings: [],
  },
  trackedSailings: [],
};

const chooseVoyageNew = createReducer(defaultState, (builder) =>
  builder
    .addCase(NEW_CHOOSE_VOYAGE_SEARCH_START, (draft) => {
      draft.error = false;
    })
    .addCase<string, PayloadAction<{ packages: FilteredPackage[]; sailings: SailingFromPackages[] }>>(
      NEW_CHOOSE_VOYAGE_SEARCH_SUCCESS,
      (draft, { payload }) => {
        draft.error = false;
        draft.filteredPackages = {
          isLoaded: true,
          packages: payload.packages,
          sailings: payload.sailings,
        };
      },
    )
    .addCase(NEW_CHOOSE_VOYAGE_SEARCH_FAILURE, (draft) => {
      draft.error = true;
      draft.filteredPackages.packages = [];
      draft.filteredPackages.sailings = [];
    })
    .addCase<string, PayloadAction<boolean>>(NEW_CHOOSE_VOYAGE_SET_LOADING, (draft, { payload }) => {
      draft.isLoading = payload;
    })
    .addCase<string, PayloadAction<PackagesData>>(NEW_CHOOSE_VOYAGE_SEARCH_API_SUCCESS, (draft, { payload }) => {
      draft.mainPackages = {
        defaultPackages: payload.defaultPackages,
        extendedPackages: groupPackagesWithSameName(payload.packages),
        fetchPayload: payload.fetchPayload,
        isLoaded: true,
        packages: payload.packages,
        sailings: payload.sailings,
      };
    })
    .addCase<string, PayloadAction<string[]>>(NEW_CHOOSE_VOYAGE_ADD_TRACKED_SAILINGS, (draft, { payload }) => {
      draft.trackedSailings = [...draft.trackedSailings, ...payload];
    })
    .addCase<string, PayloadAction<Pick<PackagesData, 'defaultGenericCategoryCodes' | 'genericCategoryCodes'>>>(
      NEW_GENERIC_CATEGORY_CODE_DATA,
      (draft, { payload }) => {
        draft.defaultGenericCategoryCodes = payload.defaultGenericCategoryCodes;
        draft.genericCategoryCodes = payload.genericCategoryCodes;
      },
    ),
);

export default chooseVoyageNew;
