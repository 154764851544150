/**
 * Google tag manager integration
 */
import omit from 'lodash/omit';

import trackerConstants from './constants';
import { trackEvent } from './dataLayer';
import eventProperties from './event/properties';
import getPage from './routesMapping';

const { BOOKING_GTM_MAPPING_V2, EVENT_ACTIONS, EVENTS, VV_EVENTS_ROOT_PREFIX } = trackerConstants;

const blacklistOptions = [
  'event',
  'location',
  'module',
  'actions',
  'label',
  'action',
  'category',
  'flyouts',
  'urlMap',
  'prefix',
];

/*
 * trackEvent() functions factory with defaults
 * Ex.:
 * const trackEvent = createTracker({
 *    prefix: trackerConstants.PREFIXES.PREFIX_1,
 *    event: trackerConstants.EVENTS.EVENT_1,
 *    module: trackerConstants.MODULES.MODULE_1,
 *    flyouts: [trackerConstants.FLYOUTS.FLYOUT_1, trackerConstants.FLYOUTS.FLYOUT_2],
 *    location: 'any_page_name',
 *    label:  trackerConstants.EVENT_LABELS.LABEL_1,
 * });
 * trackEvent({ action: trackerConstants.EVENT_ACTIONS.ACTION_1 });
 * will generate
 * {
 *   event: 'event-1',
 *   eventCategory: 'prefix_1-any_page_name|module_1|flyout_1-flyout_2',
 *   eventAction: 'action_1',
 *   eventLabel: 'label_1'
 * }
 */
export const createTracker = (defaultOptions) => (options) => {
  try {
    const {
      action,
      category,
      event = EVENTS.GTMevent,
      flyouts,
      label = '',
      location: urlPath,
      module,
      prefix = VV_EVENTS_ROOT_PREFIX,
      urlMap = BOOKING_GTM_MAPPING_V2,
    } = { ...defaultOptions, ...options };

    if (!action) {
      throw new Error("Tracker::trackGtmEvent: required param 'action' is missing");
    }

    const eventCategory =
      category ||
      eventProperties.getEventCategory({
        flyouts,
        module,
        prefix,
        urlMap,
        urlPath,
      });

    const trackableEvent = {
      event,
      eventAction: action,
      eventCategory,
      eventLabel: label,
      ...omit(options, blacklistOptions),
    };

    trackEvent(trackableEvent);
  } catch (err) {
    console.error('createTracker failed to track event', err);
  }
};

export const trackPageForPaymentLink = (reservationNumber) => {
  const data = {
    event: EVENTS.SPAPageView, // fixed value for all page view events
    flow_type: 'payment_link', // should be pushed when payment page is viewed via payment link only
    url: `${window.location.pathname}/payment-link`, // current URL path location
  };

  if (reservationNumber) {
    data.payment_link_reservation_number = reservationNumber;
  }
  trackEvent(data);
};

export const trackLinkClick = ({ ...props }) => {
  createTracker({
    event: EVENTS.GTMevent,
  })({
    action: EVENT_ACTIONS.LINK_CLICK,
    ...props,
  });
};

export const trackTellMeMoreClick = (label) => {
  const page = getPage();
  const eventPage = page === 'suites' ? 'choose-suites' : page;
  trackLinkClick({
    label,
    location: eventPage,
  });
};

export const trackVoyageProtection = (eventAction, eventLabel, totalInsuranceCost, currencyCode) => {
  const page = getPage();
  const trackingData = {
    event: 'GTMevent',
    eventAction,
    eventCategory: `VV-${page}`,
    eventLabel,
  };
  if (totalInsuranceCost && currencyCode) {
    trackingData.tripInsuranceValue = totalInsuranceCost;
    trackingData.tripInsuranceCurrency = currencyCode;
  }
  trackEvent(trackingData);
};

export const commonTracking = (eventAction, eventLabel, trackerEventCategory) => {
  const page = getPage();
  const eventPage = page === 'suites' ? 'choose-suites' : page;
  trackEvent({
    event: 'GTMevent',
    eventAction,
    eventCategory: trackerEventCategory && trackerEventCategory.length ? trackerEventCategory : `VV-${eventPage}`,
    eventLabel,
  });
};

export const trackButtonClick = ({ ...props }) => {
  createTracker({
    event: EVENTS.GTMevent,
  })({
    action: EVENT_ACTIONS.BUTTON_CLICK,
    ...props,
  });
};

export const metaCategoryPageProgressiveSummaryToggle = (isOpen) => {
  const trackingData = {
    event: 'GTMevent',
    eventAction: 'toggle-click',
    eventCategory: 'VV-choose-cabin',
    eventLabel: isOpen ? 'toggle-expand' : 'toggle-collapse',
  };
  trackEvent(trackingData);
};

export const trackExpandImageGallery = (cabinCode) => {
  const trackingData = {
    event: 'GTMevent',
    eventAction: 'icon-click',
    eventCategory: 'VV-choose-cabin',
    eventLabel: `expand-cabin-image|${cabinCode}`,
  };
  trackEvent(trackingData);
};

export const trackMetaCategoryShowOptionsCtaClick = (cabinCode) => {
  const trackingData = {
    category: 'VV-choose-cabin',
    label: `show-options|${cabinCode}`,
  };
  trackButtonClick(trackingData);
};
