import { SET_PAY_IN_FULL_PROMO_APPLIED_FLAG } from '@/constants/actionTypes';

function payInFullPromoAppliedData(state = false, action = {}) {
  switch (action.type) {
    case SET_PAY_IN_FULL_PROMO_APPLIED_FLAG:
      return action.payload;
    default:
      return state;
  }
}

const summaryReducer = {
  isPayInFullPromoApplied: payInFullPromoAppliedData,
};

export default summaryReducer;
