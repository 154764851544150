import capitalize from 'lodash/capitalize';

import {
  selectAccessible,
  selectCabinType,
  selectCabins,
  selectCurrencyCode,
  selectDurations,
  selectFromDate,
  selectMaxPrice,
  selectMinPrice,
  selectPriceType,
  selectSailors,
  selectShipList,
  selectSortType,
  selectToDate,
  selectWeekend,
} from '@/ducks/filters/selectors';
import { getFilterAgencyIsAvailable } from '@/ducks/fm/getters';
import { getMnvvReservation } from '@/ducks/mnvv/utils';
import { getSearchParams, getURLSearchParams } from '@/ducks/routes/history';

export const selectWaypoints = ({ sailing }) => {
  const portIds = getUniqPortCodeList(sailing?.ports);
  const cityName = sailing?.ports?.[0]?.name || '';

  return { cityName, portIds };
};

export const getUniqPortCodeList = (ports) => {
  if (!ports?.length) {
    return null;
  }
  const flatPortList = ports.map((port) => port?.code).filter(Boolean);
  return [...new Set(flatPortList)];
};

export const getDurationLabel = (duration) => {
  if (duration.min && duration.max) {
    return duration.min === duration.max ? duration.min : `${duration.min}-${duration.max}`;
  }
  return `${duration.min}+`;
};

export const getAllFilters = (state) => {
  const fm = getFilterAgencyIsAvailable();

  const accessible = selectAccessible(state);
  const cabinType = selectCabinType(state);
  const cabins = selectCabins(state);
  const currencyCode = selectCurrencyCode(state);
  const duration = selectDurations(state);
  const fromDate = selectFromDate(state);
  const toDate = selectToDate(state);
  const minPrice = selectMinPrice(state);
  const maxPrice = selectMaxPrice(state);
  const sailors = selectSailors(state);
  const ship = selectShipList(state);
  const sortType = selectSortType(state);
  const weekend = selectWeekend(state);
  const priceType = selectPriceType(state);

  const queryParams = getSearchParams({ parseBooleans: true, parseNumbers: true });
  const { isUpsell } = queryParams || {};
  const { isMNVV } = getMnvvReservation(getURLSearchParams());

  const isUpsellEnabled = Boolean(isUpsell);
  const isUpsellApplied = isUpsellEnabled && isUpsell === 'true';

  return {
    accessible,
    cabinType: capitalize(cabinType),

    cabins,
    currencyCode,
    duration,
    fm,

    isMNVV,
    isUpsellApplied,
    isUpsellEnabled,

    maxPrice,
    minPrice,

    priceType,
    sailors,
    ship,
    sortType,

    fromDate,
    toDate,

    weekend,
  };
};
