import type { Cookie, CookieGetOptions, CookieSetOptions } from 'universal-cookie';

import Cookies from 'universal-cookie';

import type { TOptional } from '@/types/common';

export const cookies = new Cookies();

export const checkCookieEnabled = (): boolean => cookies.get('cookieConsent_status') !== 'dismiss';

export const getCookie = (name: string, options?: TOptional<CookieGetOptions>): Cookie => cookies.get(name, options);

export type TGetCookie = typeof getCookie;

export const setCookie = (name: string, value: Cookie, options?: TOptional<CookieSetOptions>): void =>
  cookies.set(name, value, options);

export type TSetCookie = typeof setCookie;

export const removeCookie = (name: string, options?: TOptional<CookieSetOptions>): void =>
  cookies.remove(name, options);
