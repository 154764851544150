import type { TOptional } from '@/types';

import { COMMON_REQUIRED_FIELDS } from '../constants';
import getAddressRequiredFields from './getAddressRequiredFields';

export type TGetAdditionalSailorRequiredFieldsArgs = {
  countriesWithProvinces: TOptional<string[]>;
  countryCode: TOptional<string>;
  isSameAsPrimary?: boolean;
  isVoyageProtected?: boolean;
};

const getAdditionalSailorRequiredFields = ({
  countriesWithProvinces,
  countryCode,
  isSameAsPrimary,
  isVoyageProtected,
}: TGetAdditionalSailorRequiredFieldsArgs) => [
  ...COMMON_REQUIRED_FIELDS,
  ...(isVoyageProtected && !isSameAsPrimary ? getAddressRequiredFields(countryCode, countriesWithProvinces) : []),
];

export default getAdditionalSailorRequiredFields;
