import { VOYAGE_SEARCH_CABIN_EXHAUST_ERROR_SET } from '@/constants/actionTypes';

export default function cabinExhaustErrorReducer(state = false, action) {
  switch (action.type) {
    case VOYAGE_SEARCH_CABIN_EXHAUST_ERROR_SET:
      return action.payload;
    default:
      return state;
  }
}
