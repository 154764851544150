'use client';

import type { TUIResourceId, TUIResourceNode, TUIResourceValueMap } from '@/ducks/common/resources';

import { useUIResource } from '@/ducks/common/resources';

type TProps = {
  id: TUIResourceId;
  values?: TUIResourceValueMap;
};

const UIResource = ({ id, values }: TProps) => useUIResource<TUIResourceNode | string>(id, values);

export default UIResource;
