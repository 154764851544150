import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { LookupSlice } from '@/ducks/common/lookup/types';
import type { ResourcesSlice } from '@/ducks/common/resources';
import type { SettingsSlice } from '@/ducks/common/settings';

export type CommonState = {
  error?: Error;
  lookup: LookupSlice;
  resources: ResourcesSlice;
  settings: SettingsSlice;
};

const initialState: CommonState = {
  error: undefined,
  lookup: {} as LookupSlice,
  resources: {} as ResourcesSlice,
  settings: {} as SettingsSlice,
};

const commonSlice = createSlice({
  initialState,
  name: 'common',
  reducers: {
    setLookup: (state, { payload }: PayloadAction<CommonState['lookup']>) => {
      state.lookup = payload;
    },
    setResources: (state, { payload }: PayloadAction<CommonState['resources']>) => {
      state.resources = payload;
    },
    setSettings: (state, { payload }: PayloadAction<CommonState['settings']>) => {
      state.settings = payload;
    },
    throwError: (state, { payload }: PayloadAction<CommonState['error']>) => {
      state.error = payload;
    },
  },
});

export const { setLookup, setResources, setSettings, throwError } = commonSlice.actions;
export default commonSlice.reducer;
