import type { TCurrencyCode } from '@/infra/types/common';

import type { QuickSearchItems } from './types';

export const getCacheKey = (currencyCode: TCurrencyCode, sailors: number) => `${currencyCode}_${sailors}`;

export const createQuickSearchItemsRecord = (
  data: QuickSearchItems,
  currencyCode: TCurrencyCode,
  sailors: number,
  loadedAt?: number,
) => ({
  [getCacheKey(currencyCode, sailors)]: {
    data,
    loadedAt: loadedAt || Date.now(),
  },
});
