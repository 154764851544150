import { combineReducers } from 'redux';

import cabinHold from '@/ducks/cabinHold/slice';
import summary from '@/ducks/pages/summary';

import FutureVoyageCredit from './FutureVoyageCredit';
import availableZones from './availableZones';
import cabinUnhold from './cabinUnhold';
import calculateInvoice from './calculateInvoice';
import newCabinByAttributes from './newCabinByAttributes';
import sailingData from './sailingData';

export default combineReducers({
  availableZones,
  cabinHold,
  cabinUnhold,
  calculateInvoice,
  newCabinByAttributes,
  sailingData,
  ...summary.reducer,
  FutureVoyageCredit,
});
