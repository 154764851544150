import { SAILOR_FORM_RESET, SAILOR_FORM_UPDATED } from '@/constants/actionTypes';

const defaultState = {
  isSailorFormUpdated: false,
};

export default function sailorFormUpdate(store = defaultState, action) {
  switch (action.type) {
    case SAILOR_FORM_UPDATED:
      return { ...store, isSailorFormUpdated: true };
    case SAILOR_FORM_RESET:
      return { ...store, isSailorFormUpdated: false };
    default:
      return store;
  }
}
