import type { RootState } from '@/store';

const selectCruisesCommon = (state: RootState) => state?.cruisesCommon;

export const selectCruisesCommonPackagesData = (state: RootState) => selectCruisesCommon(state)?.packagesData;
export const selectCruisesCommonPackagesDataPackages = (state: RootState) =>
  selectCruisesCommonPackagesData(state)?.packages;
export const selectCruisesCommonPackagesDataSailings = (state: RootState) =>
  selectCruisesCommonPackagesData(state)?.sailings;

export const selectCruisesCommonVoyagesData = (state: RootState) => selectCruisesCommon(state)?.voyagesData;
export const selectCruisesCommonVoyagesDataSailingsCount = (state: RootState) =>
  selectCruisesCommonVoyagesData(state)?.sailingsCount;
export const selectCruisesCommonVoyagesDataRegions = (state: RootState) =>
  selectCruisesCommonVoyagesData(state)?.regions;

export const selectMapPackageCodeToRegionIds = (state: RootState) =>
  selectCruisesCommon(state)?.mapPackageCodeToRegionIds;
export const selectMapPortOfCallCodeToRegionIds = (state: RootState) =>
  selectCruisesCommon(state)?.mapPortOfCallCodeToRegionIds;
