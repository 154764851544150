/*
 If the "isInclude" arg is true, then the "item" arg will be present in the returned list,
 otherwise the "item" arg will not be present in the returned list
 If there are no changes the same instance of the "list" arg will be returned, otherwise a new instance will be created
*/
const switchListItem = (list /* :unknown[] */, item /* :unknown */, isInclude /* :boolean */) /* :unknown[] */ => {
  if (isInclude) {
    return list.includes(item) ? list : [...list, item];
  }
  const i = list.indexOf(item);
  return i < 0 ? list : [...list.slice(0, i), ...list.slice(i + 1)];
};

export default switchListItem;
