import isEmpty from 'lodash/isEmpty';

import type { TCabinCategory, TCabinCategoryPrice, TMetaCabinCategory } from '@/infra/types/cabin';
import type { TOptional } from '@/types';

export default class PriceMinMax {
  protected _max: number = -Infinity;
  protected _min: number = Infinity;

  static byCabinCategory(cabinCategory: TCabinCategory): PriceMinMax {
    const instance = new PriceMinMax();
    cabinCategory?.submetas?.forEach((subMeta) => {
      subMeta?.cabinTypes?.forEach((cabinType) => instance.update(cabinType?.bookingPriceDetails));
      instance.update(subMeta?.bookingPriceDetails);
    });
    return instance;
  }

  static byMetaCabinCategories(metaCabinCategories: TMetaCabinCategory[]): PriceMinMax {
    const instance = new PriceMinMax();
    metaCabinCategories?.forEach((metaCabinCategory) => instance.update(metaCabinCategory?.bookingPriceDetails));
    return instance;
  }

  update(bookingPriceDetails: TOptional<TCabinCategoryPrice>): void {
    if (!isEmpty(bookingPriceDetails)) {
      // Always taking value with tax
      const price = bookingPriceDetails.amount;

      const isFirstCall = this._min === Infinity && this._max === -Infinity;
      if (isFirstCall) {
        this._min = price;
        this._max = price;
        return;
      }

      if (price < this._min) {
        this._min = price;
      } else if (price > this._max) {
        this._max = price;
      }
    }
  }

  get max(): number {
    return this._max;
  }

  get min(): number {
    return this._min;
  }
}
