import compact from 'lodash/compact';
import defaults from 'lodash/defaults';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

import PriceMinMax from '@/helpers/data/PriceMinMax';
import {
  getAmountTaxAware,
  getAppliedDiscounts,
  getAverageAmountWithoutTax,
  getPriceBreakdownProps,
  getTotalPrice,
} from '@/helpers/data/mappers/Summary';
import { getSessionStorageValue } from '@/helpers/util';
import { format } from '@/helpers/util/dateUtil';
import { getAllFilters, getDurationLabel, getUniqPortCodeList, selectWaypoints } from '@/helpers/util/sailing';

import constants from '../../constants';
import { trackEvent } from '../../dataLayer';
import { createTracker } from '../../tracker';

const baseTrack = createTracker({
  event: constants.EVENTS.GTMevent,
});

export const searchCordial =
  ({ packages }) =>
  (dispatch, getState) => {
    const state = getState();

    const filters = generateFiltersData(state);

    const top3Packages = packages.slice(0, 3);
    const value = getAverageAmountWithoutTax(top3Packages);

    const sailingToTrack = packages?.[0]?.sailingList[0];
    const portIds = getUniqPortCodeList(sailingToTrack?.ports);
    const cityName = sailingToTrack?.ports?.[0]?.name || '';

    const params = defaults(
      {
        cityName,
        event: constants.EVENTS.SEARCH,
        portIds,
        value,
      },
      filters,
    );
    const transformedParams = transformAllParams(params);
    trackEvent({
      ...transformedParams,
      itinerary_name: '',
      package_code: '',
      voyage_id: '',
    });
  };

export const viewContentCordial =
  ({ cabinCategory, metaCabinCategories, sailing }) =>
  (dispatch, getState) => {
    const isRepeated = isRepeatedEntity(cabinCategory || metaCabinCategories, 'name');
    if (cabinCategory && isRepeated) {
      return;
    }

    const priceMinMax = cabinCategory
      ? PriceMinMax.byCabinCategory(cabinCategory)
      : PriceMinMax.byMetaCabinCategories(metaCabinCategories);

    const state = getState();

    const value = priceMinMax?.min;

    const cabin = cabinCategory || { name: 'any' };
    const baseParams = baseCordialEvent({ cabin, sailing });
    const filters = generateFiltersData(state);
    const priceRange = getCabinsPriceRange(state, priceMinMax?.min, priceMinMax?.max);

    const params = {
      event: constants.EVENTS.VIEW_CONTENT,
      ...filters,
      ...baseParams,
      priceRange,
      value,
    };
    const transformedParams = transformAllParams(params);

    trackEvent(transformedParams);
  };

export const addToCardContentCordial =
  ({ calculateInvoice, sailing }) =>
  (dispatch, getState) => {
    const state = getState();

    const params = {
      event: constants.EVENTS.ADD_TO_CART,
      ...checkoutBaseCordialEvent({ calculateInvoice, sailing, state }),
    };
    const transformedParams = transformAllParams(params);

    trackEvent(transformedParams);
  };

export const initiateCheckoutCordial =
  ({ calculateInvoice, sailing }) =>
  (dispatch, getState) => {
    const state = getState();

    const params = {
      event: constants.EVENTS.INITIATE_CHECKOUT,
      ...checkoutBaseCordialEvent({ calculateInvoice, sailing, state }),
    };
    const transformedParams = transformAllParams(params);

    trackEvent(transformedParams);
  };

export const addPaymentInfoCordial =
  ({ calculateInvoice, primarySailor, sailing }) =>
  (dispatch, getState) => {
    const state = getState();

    const transformedPrimarySailor = transformPrimarySailor(primarySailor);
    const params = {
      event: constants.EVENTS.ADD_PAYMENT_INFO,
      ...checkoutBaseCordialEvent({ calculateInvoice, sailing, state }),
      ...transformedPrimarySailor,
    };
    const transformedParams = transformAllParams(params);

    trackEvent(transformedParams);
  };

export const completeCheckoutCordial = ({ primarySailor, subscriptionId, ...rest }) => {
  if (!subscriptionId) {
    // eslint-disable-next-line
    console.warn('tracking newsletters without subscription', subscriptionId, primarySailor);
    return;
  }

  const transformedPrimarySailor = transformPrimarySailor(primarySailor);
  const params = {
    action: constants.EVENT_ACTIONS.FORM_COMPLETION,
    label: constants.EVENT_LABELS.NEWSLETTER_SIGNUP,
    lead_id: subscriptionId,
    ...transformedPrimarySailor,
    source: 'checkout',
  };
  const transformedParams = transformAllParams(params);

  baseTrack({ ...transformedParams, ...rest });
};

const checkoutBaseCordialEvent = ({ calculateInvoice, sailing, state }) => {
  const baseParams = baseCordialEvent({ cabin: calculateInvoice?.cabinInvoices?.[0], sailing });
  const filters = generateFiltersData(state);

  const discounts = generateDiscounts(calculateInvoice);
  const value = getTotalPrice([calculateInvoice?.cabinInvoices?.[0]]);

  const params = {
    value,
    ...filters,
    ...baseParams,
    ...discounts,
    price_range: '',
  };
  return params;
};

const baseCordialEvent = ({ cabin, sailing } = {}) => {
  const voyageParams = generateBaseVoyageData(sailing);
  const cabinParams = cabin ? generateCabinData(cabin) : undefined;
  return { ...voyageParams, ...cabinParams };
};

const generateBaseVoyageData = (sailing) => {
  const { cityName, portIds } = selectWaypoints({ sailing });
  const duration = sailing?.duration;
  const ship = sailing?.ship?.code;
  const travelDates = `${sailing?.startDate} - ${sailing?.endDate}`;
  const packageCode = sailing?.packageCode || '';
  const voyageId = sailing?.voyageId || '';
  const itineraryName = sailing?.name || '';

  return {
    cityName,
    duration,
    itineraryName,
    packageCode,
    portIds,
    ship,
    travelDates,
    voyageId,
  };
};

const generateCabinData = (cabin) => {
  const cabinType = cabin?.name || '';
  const priceRange = getAmountTaxAware(cabin?.bookingPriceDetails); // min - max between all

  return {
    cabinType,
    priceRange,
  };
};

const generateFiltersData = (state) => {
  const {
    cabinType: cabinTypeFilter,
    duration: durationFilter,
    fromDate: fromDateFilter,
    maxPrice: maxPriceFilter,
    minPrice: minPriceFilter,
    sailors: sailorsFilter,
    ship: shipCodeFilter,
    toDate: toDateFilter,
  } = getAllFilters(state);

  const arrayOfDurations = convertToArray(durationFilter);
  const duration =
    arrayOfDurations?.length === 0
      ? ['any']
      : arrayOfDurations.reduce((prev, dur) => {
          if (dur?.min && dur?.max) {
            return [...prev, getDurationLabel(dur)];
          }
          return [...prev, dur];
        }, []);

  const arrayOfShips = convertToArray(shipCodeFilter)?.map(({ id }) => id);
  const ship = !arrayOfShips?.length ? 'any' : arrayOfShips.length === 1 ? arrayOfShips[0] : arrayOfShips;

  const travelDates = fromDateFilter && toDateFilter ? `${fromDateFilter} - ${toDateFilter}` : undefined;
  const priceRange = maxPriceFilter ? `${minPriceFilter || 0}-${maxPriceFilter}` : minPriceFilter || 'any';

  const cabinType = cabinTypeFilter?.length === 0 ? 'any' : cabinTypeFilter;

  return {
    cabinType,
    duration,
    priceRange,
    sailors: sailorsFilter,
    ship,
    travelDates,
  };
};

const getCabinsPriceRange = (state, min, max) => {
  const { maxPrice: maxFilter, minPrice: minFilter } = getAllFilters(state);
  return `${(min || minFilter)?.toFixed(2)}-${(max || maxFilter)?.toFixed(2)}`;
};

const generateDiscounts = (calculateInvoice) => {
  const appliedDiscount = getAppliedDiscounts(calculateInvoice?.cabinInvoices?.[0]?.bookingPriceDetails?.discountList);
  const discountName =
    appliedDiscount?.names && appliedDiscount?.names?.length > 1
      ? appliedDiscount.names
      : appliedDiscount?.names[0] || '';
  const discountAmount = appliedDiscount?.totalDiscount;

  const priceBreakdownProps = getPriceBreakdownProps(
    calculateInvoice?.cabinInvoices,
    calculateInvoice?.paymentSchedule,
  );
  const totalAmount = priceBreakdownProps?.originalAmount;

  return {
    discountAmount,
    discountName,
    totalAmount,
  };
};

const transformPrimarySailor = (primarySailor) => {
  const {
    add_newsletter: addNewsletter,
    dob,
    email = '',
    firstname: firstName = '',
    isOptInForEmailRadio,
    isOptInForSMS,
    lastname: lastName = '',
  } = primarySailor ?? getSessionStorageValue('primarySailor') ?? {};
  return {
    addNewsletter: addNewsletter ? 'subscribed' : 'none',
    ...(isOptInForEmailRadio !== undefined && { isOptInForEmailRadio: isOptInForEmailRadio ? 'subscribed' : 'none' }),
    dob,
    email,
    firstName,
    isOptInForSMS: isOptInForSMS ? 'subscribed' : 'none',
    lastName,
  };
};

const transformAllParams = ({
  addNewsletter,
  cabinType,
  cityName,
  discountAmount,
  discountName,
  dob,
  duration,
  email,
  firstName,
  isOptInForEmailRadio,
  isOptInForSMS,
  itineraryName,
  lastName,
  packageCode,
  portIds,
  priceRange,
  sailors,
  ship,
  totalAmount,
  travelDates,
  value,
  voyageId,
  ...rest
}) => ({
  ...(value && { value: value?.toFixed(2)?.toString() }),
  ...(portIds && { port_ids: portIds }),
  ...(cityName && { city: cityName }),
  ...(duration && { duration: duration?.toString()?.replaceAll(',', ', ') }),
  ...(priceRange && {
    price_range: Array.isArray(priceRange) ? priceRange : priceRange?.toString(),
  }),
  ...(ship && { ship }),
  ...(cabinType && { cabin_type: cabinType }),
  ...(travelDates && { travel_dates: travelDates }),
  ...(packageCode && { package_code: packageCode }),
  ...(voyageId && { voyage_id: voyageId }),
  ...(sailors && { number_of_sailors: sailors?.toString() }),
  ...(itineraryName && { itinerary_name: itineraryName }),

  ...(isNumber(discountAmount) && {
    order_discount_offer_amount: (Math.abs(discountAmount) || 0)?.toFixed(2)?.toString(),
  }),
  ...(isString(discountName) && {
    order_discount_offer_name: discountName,
  }),
  ...(totalAmount && { voyage_total: totalAmount?.toFixed(2)?.toString() }),

  ...(firstName && { first_name: firstName }),
  ...(lastName && { last_name: lastName }),
  ...(email && { email }),
  ...(dob && { person_birthdate: format(dob, 'yyyyMMdd') }),
  ...(addNewsletter && { subscriptionStatus: addNewsletter }),
  ...(isOptInForEmailRadio && { subscriptionStatus: isOptInForEmailRadio }),
  ...(isOptInForSMS && { mobile_opt_in_subscriptionStatus: isOptInForSMS }),
  ...rest,
});

const prevEntityValues = new Map();
export const isRepeatedEntity = (obj, field) => {
  const isRepeated = prevEntityValues.has(obj);
  prevEntityValues.set(obj, obj[field]);

  return isRepeated;
};

const convertToArray = (arr) => compact(Array.isArray(arr) ? arr : [arr]);
