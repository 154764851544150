import React from 'react';

import filter from 'lodash/filter';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import reduce from 'lodash/reduce';
import set from 'lodash/set';
import sumBy from 'lodash/sumBy';

import UIResource from '@/components/UIResource';
import { getExampleAllSailorFeatures } from '@/components/WhatsIncluded/__tests__/invariants';
import { getSearchParams } from '@/ducks/routes/history';
import { getIsTaxesIncluded } from '@/helpers/pricing/lib/tax';
import { formatDayByDate } from '@/helpers/util/dateUtil';
import { getBookingSource } from '@/helpers/util/misc';
import { getVWOValue } from '@/helpers/vwo/getVWOValue';
import { FiltersPriceType } from '@/infra/types/common/filters';
import { VWOFlag } from '@/types/vwo';

import { buildCabinFullNumber } from './Cabin';

export const getTotalPrice = (cabinsSelected, priceObjectKey = 'bookingPriceDetails') =>
  reduce(
    cabinsSelected,
    (sum, cabin) => {
      const totalPrice = sum + get(cabin, `${priceObjectKey}.amount`, 0);
      return totalPrice - get(cabin, `${priceObjectKey}.tripInsurance`, 0);
    },
    0,
  );

export const getGrandTotal = (cabinInvoice, priceObjectKey = 'bookingPriceDetails') =>
  get(cabinInvoice, `${priceObjectKey}.amount`, 0) - get(cabinInvoice, `${priceObjectKey}.tripInsurance`, 0);

export const getVoyageTax = (cabinsSelected, priceObjectKey = 'bookingPriceDetails') =>
  cabinsSelected.reduce((sum, cabin) => {
    const taxAmount = sum + get(cabin, `${priceObjectKey}.taxAmount`, 0);
    return taxAmount;
  }, 0);

export const getIncluded = (allIncludes) => {
  const includesIds = map(allIncludes, (inc) => inc.Id);

  return filter(getExampleAllSailorFeatures('/static/images/cabin-detail/features/'), (inc) =>
    includesIds.includes(inc.id),
  );
};

export const getFinalPrice = (cabinInvoices, paymentSchedule) => {
  const finalAmount = reduce(
    paymentSchedule,
    (sum, schedule) => sum + get(schedule, 'finalAmount.amount', 0) + get(schedule, 'depositAmount.amount', 0),
    0,
  );
  const tripInsurance = reduce(
    cabinInvoices,
    (sum, invoice) => sum + get(invoice, 'bookingPriceDetails.tripInsurance', 0),
    0,
  );
  return finalAmount - tripInsurance; // eslint-disable-line
};

export const getDiscount = (cabinInvoices = []) => sumBy(cabinInvoices, 'bookingPriceDetails.discount');

export const getAppliedDiscounts = (discountList) => {
  let appliedDiscounts;
  if (discountList?.length) {
    appliedDiscounts = discountList.reduce(
      (accumulator, currentDiscount) => {
        if (currentDiscount?.amount < 0) {
          accumulator.names.push(currentDiscount?.name);
          accumulator.codes.push(currentDiscount?.priceTypeText);
          accumulator.totalDiscount += currentDiscount?.amount || 0;
        }
        return accumulator;
      },
      { codes: [], names: [], totalDiscount: 0 },
    );
  }
  return appliedDiscounts;
};

export const getDepositAmount = (paymentSchedule) =>
  reduce(paymentSchedule, (sum, schedule) => sum + get(schedule, 'depositAmount.amount', 0), 0);

export const getAmountPerItem = (
  price,
  { cabins = 1, priceType = FiltersPriceType.perSailor, sailors },
  key = 'amount',
) => {
  if (priceType === FiltersPriceType.perCabin || priceType === FiltersPriceType.sailorPerNight) {
    return getAmountTaxAware(price, key) / cabins;
  }
  if (priceType === FiltersPriceType.perSailor) {
    return getAmountTaxAware(price, key) / sailors;
  }
  return getAmountTaxAware(price, key) / sailors;
};

export const getAmountPerItemFlat = (price, { cabins = 1, priceType = FiltersPriceType.perSailor, sailors }) => {
  if (priceType === FiltersPriceType.perCabin || priceType === FiltersPriceType.sailorPerNight) {
    return price / cabins;
  }
  if (priceType === FiltersPriceType.perSailor) {
    return price / sailors;
  }
  return price / sailors;
};

export const getAmountPerItemNew = (
  price,
  duration,
  { cabins = 1, priceType = FiltersPriceType.perSailor, sailors },
  key = 'amount',
) => {
  switch (priceType) {
    case FiltersPriceType.perCabin:
      return getAmountTaxAware(price, key) / cabins;
    case FiltersPriceType.perSailor:
      return getAmountTaxAware(price, key) / sailors;
    case FiltersPriceType.sailorPerNight:
      return getAmountPerVoyage(price, duration, { sailors }, key);
    default:
      return getAmountTaxAware(price, key) / sailors;
  }
};

/* type TPriceTypeOptions = {
  cabins?: number
  duration?: number
  sailors?: number
} */

export const dividePriceByType = (
  priceAmount /* :number */,
  priceType /* :PRICE_TYPE */,
  priceOptions /* :TPriceTypeOptions */,
) => {
  const { cabins, duration, sailors } = priceOptions || {};
  switch (priceType) {
    case FiltersPriceType.perCabin:
      return priceAmount / (cabins || 1);
    case FiltersPriceType.sailorPerNight:
      return priceAmount / (duration || 1) / (sailors || 1);
    case FiltersPriceType.perSailor:
    default:
      return priceAmount / (sailors || 1);
  }
};
export const getSailingForSailorPerNight = (sailings, { sailors }) => {
  const toBeAppliedPriceRange = {};
  const newSailings = orderBy(sailings, (s) => getAmountPerVoyage(s.startingPrice, s.duration, { sailors }), 'asc');
  if (newSailings.length === 0) {
    return {};
  }

  toBeAppliedPriceRange.minPrice =
    newSailings && Math.floor(getAmountPerVoyage(newSailings[0].startingPrice, newSailings[0].duration, { sailors }));
  toBeAppliedPriceRange.maxPrice =
    newSailings &&
    Math.ceil(
      getAmountPerVoyage(
        newSailings[newSailings.length - 1].startingPrice,
        newSailings[newSailings.length - 1].duration,
        { sailors },
      ),
    );
  return { newSailings, toBeAppliedPriceRange };
};

export const getAmountPerVoyage = (price, duration, { sailors }, key = 'amount') =>
  getAmountTaxAware(price, key) / sailors / duration;

export const getAmountPerVoyageFlat = (price, duration, { sailors }) => price / sailors / duration;

export const getSailings = (sailings, { cabins, priceType, sailors }) => {
  const toBeAppliedPriceRange = {};
  const newSailings = orderBy(
    sailings,
    (s) => getAmountPerItem(s.startingPrice, { cabins, priceType, sailors }),
    'asc',
  );
  try {
    toBeAppliedPriceRange.minPrice =
      newSailings && Math.floor(getAmountPerItem(newSailings[0].startingPrice, { cabins, priceType, sailors }));
    toBeAppliedPriceRange.maxPrice =
      newSailings &&
      Math.ceil(getAmountPerItem(newSailings[newSailings.length - 1].startingPrice, { cabins, priceType, sailors }));
  } catch (e) {
    // console.log('error while cal min and max price', e);
  }
  return { newSailings, toBeAppliedPriceRange };
};
export const getAmountTaxAware = (price = {}, key = 'amount') => {
  const { taxAmount } = price;
  const isTaxesIncluded = getIsTaxesIncluded();
  let result = parseFloat(price[key]);
  if (isTaxesIncluded) {
    return result;
  }

  return result - parseFloat(taxAmount);
};

/* type TPriceDetails = {
  amount: number
  tripInsurance: number
  taxAmount: number
}
type TPriceOptions = TPriceTypeOptions & {
  isInsuranceInclusive?: boolean
  isTaxInclusive?: boolean
} */
export const calcDisplayPrice = (
  priceDetails /* :TPriceDetails */,
  priceType /* :PRICE_TYPE */,
  priceOptions /* :TPriceOptions */,
) /* :number */ => {
  const { isInsuranceInclusive, isTaxInclusive } = priceOptions || {};
  let price = priceDetails?.amount || 0;
  if (!isTaxInclusive) {
    price -= priceDetails?.taxAmount || 0;
  }
  if (!isInsuranceInclusive) {
    price -= priceDetails?.tripInsurance || 0;
  }
  return dividePriceByType(price, priceType, priceOptions);
};

export const getAverageAmountWithoutTax = (packages) => {
  if (!packages.length) {
    return 0;
  }

  const packagePriceList = packages.map((packageData) => {
    if (!packageData?.startingPrice?.originalAmount) {
      return 0;
    }

    const {
      startingPrice: { originalAmount, taxAmount },
    } = packageData;
    const price = originalAmount - taxAmount;
    return price;
  });

  const totalVoyagePrice = packagePriceList.reduce((a, b) => a + b, 0);
  const value = Number(totalVoyagePrice / packagePriceList.length);

  return value;
};

export const getCabinPriceItems = (cabin) => {
  const isTaxesAndFeesFeatureEnabled = getVWOValue({ key: VWOFlag.TAXES_AND_FEES });
  const items = {};

  items.sailors = (cabin?.guestPriceDetails || []).map((guestDetail, index) => ({
    name: `Sailor ${index + 1}`,
    value:
      get(guestDetail, 'originalAmount', 0) -
      get(guestDetail, 'tripInsurance', 0) -
      get(guestDetail, 'taxAmount', 0) -
      get(guestDetail, 'portFees', 0),
  }));

  items.gratuities = {
    name: <UIResource id="VoyagePlanner.Summary.Gratuities" />,
    value: cabin?.bookingPriceDetails?.gratuities,
  };
  items.taxes = {
    name: <UIResource id="VoyagePlanner.Summary.TaxesAndFees" />,
    value: get(cabin, 'bookingPriceDetails.taxAmount', 0) + get(cabin, 'bookingPriceDetails.portFees', 0),
  };

  if (isTaxesAndFeesFeatureEnabled) {
    items.portFees = {
      isNoHeading: true,
      name: <UIResource id="Summary.FareBreakdown.portFees" />,
      value: get(cabin, 'bookingPriceDetails.portFees', 0),
    };
    items.govtFees = {
      isNoHeading: true,
      name: <UIResource id="Summary.FareBreakdown.governmentTaxes" />,
      value: get(cabin, 'bookingPriceDetails.taxAmount', 0),
    };
  }

  return items;
};

export const getCabins = (cabinInvoices) => {
  const cabins = map(cabinInvoices, (cabin) => ({
    addOnPriceDetails: cabin.addOnPriceDetails,
    heading: cabin.name,
    items: getCabinPriceItems(cabin),
    total: get(cabin, 'bookingPriceDetails.originalAmount', 0) - get(cabin, 'bookingPriceDetails.tripInsurance', 0),
    whatsIncluded: cabin.whatsIncluded,
  }));
  return cabins;
};

export const getSailorsAmount = (cabins) =>
  cabins?.reduce(
    (sumCabins, cabin) =>
      sumCabins + (cabin?.items?.sailors?.reduce((sumPerCabin, sailor) => sumPerCabin + sailor.value, 0) ?? 0),
    0,
  );

export const getPriceBreakdownProps = (cabinInvoices, paymentSchedule) => {
  const result = {};
  const totalAmt = getFinalPrice(cabinInvoices, paymentSchedule);
  const depositAmt = getDepositAmount(paymentSchedule);
  const discount = getDiscount(cabinInvoices);
  const discountList = get(cabinInvoices, '0.bookingPriceDetails.discountList', []);
  const originalAmount =
    get(cabinInvoices, '0.bookingPriceDetails.originalAmount', 0) -
    get(cabinInvoices, '0.bookingPriceDetails.tripInsurance', 0);
  const cabins = getCabins(cabinInvoices);
  const sailorsAmount = getSailorsAmount(cabins);

  result.total = totalAmt;
  result.currencyCode = get(cabinInvoices, '0.bookingPriceDetails.currencyCode');
  result.deposit = {
    due: 'today', // TODO: update this value with dunamic in R2 paymentSchedule[0].depositAmount.dueDate,
    dueDate: formatDayByDate(paymentSchedule?.[0]?.finalAmount?.dueDate, 'MMM dd, yyyy'),
    percentage: 20, // TODO: update this value with dunamic in R2 tMath.ceil((depositAmt / totalAmt) * 100),
    remaining: paymentSchedule?.[0]?.finalAmount?.amount,
    value: depositAmt,
  };
  result.cabins = cabins;
  result.numSailors = get(cabinInvoices, '0.guestCount');
  result.sailorsAmount = sailorsAmount;
  result.discount = !isNil(discount) ? discount : 0;
  result.discountList = discountList;
  result.originalAmount = originalAmount;
  result.paidAmount = get(cabinInvoices, '0.bookingPriceDetails.paidAmount', 0);
  result.totalDue = get(cabinInvoices, '0.bookingPriceDetails.totalDue', 0);
  return result;
};

export const getCabinObj = (cabin, idx) => {
  const cabinObj = {
    cabin: {
      cabinNumber: cabin.cabinNumber,
      cabinSide: cabin.cabinSide,
      deck: cabin.deck,
      position: cabin.position,
    },
    // eslint-disable-next-line radix
    count: parseInt(idx, 0) + 1,
    extras: get(cabin, 'whatsIncluded', []),
    heading: get(cabin, 'name', ''),
    images: get(cabin, 'landscapeGallery', []),
    isJoker: get(cabin, 'isJoker', false),
    isVip: get(cabin, 'isVip', false),
    location: {
      alt: get(cabin, 'shipLocationImage.alt', ''),
      src: get(cabin, 'shipLocationImage.src', ''),
    },
    onEdit: () => {},
    onLocation: () => {},
    preferences: cabin.cabinAttributes,
    sailors: cabin.guestCount,
    tags: get(cabin, 'tags', []),
  };

  return cabinObj;
};

export const getUnholdBody = (cabinInvoice, sailingData) =>
  makeUnholdApiArgs(buildCabinFullNumber(cabinInvoice), sailingData);

export const makeUnholdApiArgs = (cabinNumber, sailingData) => {
  const { debarkDate, embarkDate, voyageId } = sailingData || {};
  const { currencyCode, shipCode } = getSearchParams();
  return {
    cabinDetails: [
      {
        cabinNumber,
        debarkDate: debarkDate || '',
        embarkDate: embarkDate || '',
      },
    ],
    shipCode: shipCode || '',
    voyageId: voyageId || '',
    ...getBookingSource(currencyCode || 'USD', false, false),
  };
};

// export const getAvailableZonesBody = () => {
//   const parsedParameters = getSearchParams();
//   return {
//     categoryCode: get(parsedParameters, 'cabinType'),
//     guestCount: Number(get(parsedParameters, 'sailors', 2)),
//     isAccessible: get(parsedParameters, 'isCabinAccessible', 'false') === 'true',
//     shipCode: get(parsedParameters, 'shipCode'),
//     voyageId: get(parsedParameters, 'voyageId'),
//   };
// };

export const mapNewCabinToCalculateInvoice = (calculateInvoice, newCabinResult, zone) => {
  map(get(calculateInvoice, 'cabinInvoices', []), (CI, index) => {
    /* eslint-disable no-param-reassign */
    const cabinNumber = get(newCabinResult, `cabins[${index}].cabinNumber`, '');
    CI.cabinNumber = cabinNumber.substring(cabinNumber.length >= 6 ? 2 : 1, cabinNumber.length - 1);
    CI.deck = cabinNumber.substring(0, cabinNumber.length >= 6 ? 2 : 1);
    CI.cabinSide = get(cabinNumber, `[${cabinNumber.length - 1}]`);
    CI.guestPriceDetails = map(get(newCabinResult, `cabins[${index}].priceDetail.guestPrices`, []), (guestPrice) => ({
      amount: get(guestPrice, 'amount'),
      currencyCode: get(guestPrice, 'currencyCode'),
      taxAmount: get(guestPrice, 'taxAmount'),
      tripInsurance: get(guestPrice, 'insuranceAmount'),
    }));
    CI.zone = `${get(zone, 'zoneId')}`;
    CI.zoneDescription = get(zone, 'description', '');
    CI.shipLocationImage = get(newCabinResult, `cabins[${index}].shipLocationImage`, {});
    return CI;
  });
  set(
    calculateInvoice,
    'paymentSchedule[0].finalAmount',
    get(newCabinResult, 'cabins[0].priceDetail.paymentSchedule[0].finalAmount'),
  );
  set(
    calculateInvoice,
    'paymentSchedule[0].depositAmount',
    get(newCabinResult, 'cabins[0].priceDetail.paymentSchedule[0].depositAmount'),
  );
  return calculateInvoice;
};
