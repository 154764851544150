import { selectMainPackages, selectMainSailings } from '@/ducks/pages/chooseVoyage/selectors';
import {
  selectCelebrationsIsFlowActive,
  selectCelebrationsPackages,
  selectCelebrationsSailings,
} from '@/ducks/programs/celebrations/selectors';
import { selectSailingData, selectSelectedPackage } from '@/ducks/voyageInfo/sailing/selectors';

export const selectSpecificSailing =
  ({ packageCode }) =>
  (state) => {
    const isCelebrationActive = selectCelebrationsIsFlowActive(state);

    let sailings;
    if (isCelebrationActive) {
      sailings = selectCelebrationsSailings(state);
    } else {
      sailings = selectMainSailings(state);
    }

    const specificSailing = (sailings || []).find((s) => s.packageCode === packageCode);
    return specificSailing;
  };

export const selectSpecificPackage =
  ({ packageCode }) =>
  (state) => {
    const isCelebrationActive = selectCelebrationsIsFlowActive(state);

    let packages;
    if (isCelebrationActive) {
      packages = selectCelebrationsPackages(state);
    } else {
      packages = selectMainPackages(state);
    }

    const specificPackage = packages.find((p) => p.packageCode === packageCode);
    return specificPackage;
  };

export const selectShownSailing = (state) => {
  const sailingData = selectSailingData(state);
  const celebrationSailing = selectCelebrationsSailings(state)?.[0];

  const isCelebrationActive = selectCelebrationsIsFlowActive(state);

  return isCelebrationActive ? celebrationSailing : sailingData;
};

export const selectShownPackage = (state, searchParams) => {
  const selectedPackage = selectSelectedPackage(state, searchParams);
  const celebrationPackage = selectCelebrationsPackages(state)?.[0] || {};

  const isCelebrationActive = selectCelebrationsIsFlowActive(state);

  return isCelebrationActive ? celebrationPackage : selectedPackage;
};
