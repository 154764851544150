import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';

import type { TLookupShip } from '@/ducks/common/lookup/types';
import type { SailingFromPackages, TSailingData, TSailingDataResponse } from '@/infra/types/voyageInfo/sailing';
import type { TOptional } from '@/types/common';

const NON_PORT_NAME = 'sailing';

const combineSailingData = (
  sailing: TSailingData | TSailingDataResponse,
  sailings: SailingFromPackages[],
  ships: TLookupShip[],
): TOptional<TSailingData> => {
  if (isEmpty(sailing)) {
    return sailing ? (sailing as TSailingData) : undefined;
  }
  const voyageId = sailing?.voyageId || (sailing as TSailingData)?.id;
  const pkgSailing = voyageId ? sailings?.find((sailing: SailingFromPackages) => sailing.id === voyageId) : undefined;
  // eslint-disable-next-line prefer-const
  let { ship, startingPrice } = pkgSailing || ({} as SailingFromPackages);
  if (!ship && voyageId) {
    const lookupShip = ships?.find((item) => voyageId.startsWith(item.id));
    if (lookupShip) {
      ship = { code: lookupShip.id, name: lookupShip.name };
    }
  }
  const portsWithoutSailings =
    sailing?.itinerary?.filter((itinerary) => !itinerary?.portName?.toLowerCase().includes(NON_PORT_NAME)) || [];
  const portsFormatted = portsWithoutSailings.map((port) => ({ ...port, code: port.portCode, name: port.portName }));
  const position = findIndex(sailings, sailing) + 1;

  return {
    ...sailing,
    endDate: sailing?.debarkDate,
    id: voyageId,
    packageName: sailing?.name || pkgSailing?.packageName,
    ports: portsFormatted,
    position,
    ship,
    startDate: sailing?.embarkDate,
    startingPrice: (sailing as TSailingData)?.startingPrice || startingPrice,
  } as TSailingData;
};

export default combineSailingData;
