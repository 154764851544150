export const CELEBRATIONS_ACTIVE_SUCCESS = 'CELEBRATIONS_ACTIVE_SUCCESS';
export const CELEBRATIONS_ACTIVE_FAILURE = 'CELEBRATIONS_ACTIVE_FAILURE';
export const CELEBRATIONS_RESET = 'CELEBRATIONS_RESET';

export const CELEBRATION_LINK_REFERRAL_CODE_SUCCESS = 'CELEBRATION_LINK_REFERRAL_CODE_SUCCESS';
export const CELEBRATION_LINK_REFERRAL_CODE_FAILURE = 'CELEBRATION_LINK_REFERRAL_CODE_FAILURE';

export const CELEBRATION_EXIT_REFERRED_BOOKER_FLOW = 'CELEBRATION_EXIT_REFERRED_BOOKER_FLOW';

export const COMPLETE_CELEBRATION_PROGRAM = 'COMPLETE_CELEBRATION_PROGRAM';

export const CELEBRATION_FETCH_PRODUCT_SUCCESS = 'CELEBRATION_FETCH_PRODUCT_SUCCESS';
export const CELEBRATION_FETCH_PRODUCT_FAILURE = 'CELEBRATION_FETCH_PRODUCT_FAILURE';
