import type { Port } from '@/infra/types/voyageInfo/port';

export type TFilters = {
  accessible?: boolean;
  agencyId?: string;
  agentId?: string;
  cabinType?: string;
  cabins: number;
  chooseCabinSort?: 'priceAsc';
  currencyCode?: 'USD';
  durations: TFiltersDuration[];
  fromDate?: string;
  homePorts?: string[];
  initialized?: boolean;
  maxPrice: string;
  minPrice: string;
  nodes?: string[];
  packages?: string[];
  ports?: string[];
  priceType?: FiltersPriceType;
  promotionalVoyages?: string[];
  resetAll?: boolean;
  sailors: number;
  selectedRegions: {
    name: string;
    packages: string[];
    ports: string[];
    regionId: string;
  }[];
  selectedRegionsIds: string[];
  shipList: string[];
  sortType: string;
  toDate: string;
  weekend: boolean;
};

export type TFiltersDuration = { animation: string; max?: number; min: number };

export type TFiltersOptions = {
  cabinTypes: { code: string; name: string }[];
  defaultEndDate: string;
  defaultFilters: {
    cabins: number;
    chooseCabinSort: FiltersSortType;
    fromDate: string;
    maxPrice: number;
    minPrice: number;
    priceType: FiltersPriceType;
    sailors: number;
    sortType: FiltersSortType;
    toDate: string;
  };
  defaultStartDate: string;
  durationOptions: TFiltersDuration[];
  homePorts: Port[];
  maxCabinCount: number;
  maxCabinOccupancy: number;
  maxDate: string;

  minCabinCount: number;
  minCabinOccupancy: number;
  minDate: string;
};

export enum FiltersPriceType {
  perCabin = 'perCabin',
  perCabinExact = 'perCabinExact',
  perSailor = 'perSailor',
  perSuiteExact = 'perSuiteExact',
  sailorPerNight = 'SailorPerNight',
}

export enum FiltersSortType {
  date = 'date',
  durationAsc = 'duration-asc',
  durationDesc = 'duration-desc',
  priceAsc = 'price-asc',
  priceDesc = 'price-desc',
  recommended = 'recommended',
}
