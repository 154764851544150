import kebabCase from 'lodash/kebabCase';

import { ensureArray } from '@/helpers/util';
import { trackButtonClick, trackIconClick, trackLinkClick, trackPopupState } from '@/tagmanager/event/types/common';

import constants from '../../constants';
import { createTracker } from '../../tracker';
import builders from '../builders';

const trackEvent = createTracker({
  event: constants.EVENTS.GTMevent,
});

export const trackShowFilters = () =>
  trackButtonClick({
    eventEmitter: trackEvent,
    labels: [constants.EVENT_LABELS.VOYAGE_FILTERS.TO_REFINEMENT_FILTERS],
  });

export const trackOpenMainFilters = ({ name, value }) => {
  trackLinkClick({
    eventEmitter: trackEvent,
    labels: [name, value],
    labelsOptions: { lowerCase: false },
  });
};

export const trackShowFiltersResult = ({ label, results }) => {
  trackButtonClick({
    eventEmitter: trackEvent,
    labels: [label],
    module: constants.FLYOUTS.REFINEMENT_RESULTS,
    number_of_filtering_results: results?.toString(),
  });
};

export const trackOpenSubFilter = ({ label }) => {
  trackButtonClick({
    eventEmitter: trackEvent,
    labels: [ensureArray(label)?.join('')],
    module: constants.FLYOUTS.REFINEMENT_RESULTS,
  });
};

export const trackHideFilters = () =>
  trackPopupState({
    isOpen: false,
    module: constants.FLYOUTS.REFINEMENT_RESULTS,
  });

export const trackApplyFilter = ({ name, results = null, values = [], ...args }) => {
  // skip the first, there case sensitive labels
  const isOneOrSecondNonEmpty = (index, array) => array.length === 1 || (array.length > 1 && index > 0);
  const transformedValues = values.map((val, index, array) =>
    isOneOrSecondNonEmpty(index, array) ? kebabCase(val) : val,
  );

  const filteredResults = results ? { number_of_filtering_results: results?.toString() } : {};
  const label = builders.buildLabel([name, ...transformedValues]);
  trackEvent({
    action: constants.EVENT_ACTIONS.FILTER_APPLIED,
    label,
    ...filteredResults,
    ...args,
  });
};

export const trackRemoveFilter = ({ name }) => {
  const multiName = (Array.isArray(name) ? name.join(',') : name).replace(/, /g, ',').replace(/ - /g, '-');
  trackIconClick({
    labels: [constants.EVENT_LABELS.VOYAGE_FILTERS.RESET_FILTER, multiName],
  });
};

export const trackReApplyFilters = () =>
  trackButtonClick({
    eventEmitter: trackEvent,
    labels: [constants.EVENT_LABELS.VOYAGE_FILTERS.SEARCH_AGAIN],
  });
