import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { CruiseCommonSlice, PackagesData, VoyagesDataResponse } from '@/infra/types/voyageInfo/package';

import { calculateAdditonalVoyagesData } from './utils';

export const initialCruisesCommonState: CruiseCommonSlice = {
  mapPackageCodeToRegionIds: {},
  mapPortOfCallCodeToRegionIds: {},
  packagesData: {
    defaultGenericCategoryCodes: [],
    defaultPackages: [],
    //
    genericCategoryCodes: [],
    packages: [],
    sailings: [],
  },
  voyagesData: {
    cabinTypes: [],
    classifications: [],
    defaultEndDate: null,
    defaultStartDate: null,
    durations: [],
    maxDate: null,
    minDate: null,
    packages: [],
    regions: [],
    tacticalRates: [],
  },
};

const cruisesCommonSlice = createSlice({
  initialState: initialCruisesCommonState,
  name: 'cruisesCommon',
  reducers: {
    setCruiseCommonPackagesData: (state, { payload }: PayloadAction<PackagesData>) => {
      state.packagesData = payload;
    },
    setCruiseCommonVoyagesData: (state, { payload }: PayloadAction<VoyagesDataResponse>) => {
      state.voyagesData = payload;

      const data = calculateAdditonalVoyagesData(payload);
      state.mapPackageCodeToRegionIds = data.mapPackageCodeToRegionIds;
      state.mapPortOfCallCodeToRegionIds = data.mapPortOfCallCodeToRegionIds;
    },
  },
});

export const { setCruiseCommonPackagesData, setCruiseCommonVoyagesData } = cruisesCommonSlice.actions;
export default cruisesCommonSlice.reducer;
