import { WHATS_INCLUDED_ERROR, WHATS_INCLUDED_SUCCESS } from '@/constants/actionTypes';

const initialState = { data: [], error: {}, loaded: false };

export default function whatsIncludedReducer(store = initialState, action = {}) {
  switch (action.type) {
    case WHATS_INCLUDED_SUCCESS:
      return { ...store, data: action.payload, error: {}, loaded: true };
    case WHATS_INCLUDED_ERROR:
      return { ...store, data: {}, error: action.payload };
    default:
      return store;
  }
}
