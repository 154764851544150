import { type TOptional } from '@/types/common';

export type TParams = Record<string, string | string[]>;

const searchParamsAsObject = <R extends TParams = TParams>(searchParams: TOptional<URLSearchParams>): TOptional<R> => {
  type TKey = keyof R;
  type TValue = R[TKey];
  if (searchParams?.toString()?.length) {
    const result = {} as R;
    [...searchParams.entries()].forEach(([name, value]: [TKey, string]) => {
      if (name in result) {
        const prev = result[name];
        const next: string[] = Array.isArray(prev) ? prev : [prev!];
        next.push(value);
        result[name] = next as TValue;
      } else {
        result[name] = value as TValue;
      }
    }, {} as R);
    return result;
  }
};

export default searchParamsAsObject;
