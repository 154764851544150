import {
  FILTERS_APPLY,
  META_CABIN_CATEGORY_DATA_ERROR,
  META_CABIN_CATEGORY_DATA_LOADING,
  META_CABIN_CATEGORY_DATA_RESET,
  META_CABIN_CATEGORY_DATA_SUCCESS,
  META_CABIN_CATEGORY_SET_UNAVAILABLE,
} from '@/constants/actionTypes';
import sortMetaCategoriesByType from '@/ducks/pages/chooseCabin/helpers/sortMetaCategoriesByType';

const updateMetaCabinCategoryAvailability = (metas, code) =>
  [...metas].map((meta) => (meta.code === code ? { ...meta, isAvailable: false } : { ...meta }));

const defaultState = {
  data: [],
  error: {},
  isLoaded: false,
  isLoading: false,
};

export default function metaCabinCategoriesReducer(store = defaultState, action) {
  switch (action.type) {
    case FILTERS_APPLY:
      return {
        ...store,
        data: sortMetaCategoriesByType(store.data, action.payload.chooseCabinSort),
      };
    case META_CABIN_CATEGORY_DATA_SUCCESS:
      return {
        ...store,
        data: sortMetaCategoriesByType(action.payload.metaCabinCategories, action.payload.chooseCabinSort),
        error: action.payload.metaCabinCategories?.length ? {} : { isFetchMetaCabinCategoriesResponseEmpty: true },
        isLoaded: true,
        isLoading: false,
      };
    case META_CABIN_CATEGORY_SET_UNAVAILABLE:
      return {
        ...store,
        data: sortMetaCategoriesByType(
          updateMetaCabinCategoryAvailability(store.data, action.payload.unavailableMetaCabinCategoryCode),
          action.payload.chooseCabinSort,
        ),
      };
    case META_CABIN_CATEGORY_DATA_ERROR:
      return {
        ...store,
        data: defaultState.data,
        error: action.payload,
        isLoading: false,
      };
    case META_CABIN_CATEGORY_DATA_LOADING:
      return { ...store, isLoading: true };
    case META_CABIN_CATEGORY_DATA_RESET:
      return defaultState;
    default:
      return store;
  }
}
