import {
  CONFIRMATION_SET_TRACKED_RESERVATION_NUMBER_ERROR,
  CONFIRMATION_SET_TRACKED_RESERVATION_NUMBER_SUCCESS,
} from '@/constants/actionTypes';

export default function tracledReservationNumberReducer(store = { data: {}, error: {} }, action) {
  switch (action.type) {
    case CONFIRMATION_SET_TRACKED_RESERVATION_NUMBER_SUCCESS:
      return { ...store, data: action.payload, error: {} };
    case CONFIRMATION_SET_TRACKED_RESERVATION_NUMBER_ERROR:
      return { ...store, data: {}, error: action.payload };
    default:
      return store;
  }
}
