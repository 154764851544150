import { type TMetaCabinCategory } from '@/infra/types/cabin';
import { FiltersSortType } from '@/infra/types/common/filters';

const sortLowestPriceFirst = (metaCabinCategories: TMetaCabinCategory[]) =>
  [...metaCabinCategories].sort(
    (a, b) =>
      (b.isAvailable ? 1 : 0) - (a.isAvailable ? 1 : 0) ||
      (a.bookingPriceDetails?.amount || 0) - (b.bookingPriceDetails?.amount || 0),
  );

const sortHighestPriceFirst = (metaCabinCategories: TMetaCabinCategory[]) =>
  [...metaCabinCategories].sort(
    (a, b) =>
      (b.isAvailable ? 1 : 0) - (a.isAvailable ? 1 : 0) ||
      (b.bookingPriceDetails?.amount || 0) - (a.bookingPriceDetails?.amount || 0),
  );

const sortByDefault = (metaCabinCategories: TMetaCabinCategory[]) =>
  [...metaCabinCategories].sort(
    (a, b) =>
      (b.isAvailable ? 1 : 0) - (a.isAvailable ? 1 : 0) ||
      (+a.recommendedSortPriority || 0) - (+b.recommendedSortPriority || 0),
  );

const sortMetaCategoriesByType = (metaCabinCategories: TMetaCabinCategory[], sortType: FiltersSortType) => {
  switch (sortType) {
    case FiltersSortType.recommended:
      return sortByDefault(metaCabinCategories);
    case FiltersSortType.priceAsc:
      return sortLowestPriceFirst(metaCabinCategories);
    case FiltersSortType.priceDesc:
      return sortHighestPriceFirst(metaCabinCategories);
    default:
      return sortByDefault(metaCabinCategories);
  }
};

export default sortMetaCategoriesByType;
