import { SUMMARY_UNHOLD_CABIN_ERROR, SUMMARY_UNHOLD_CABIN_SUCCESS } from '@/constants/actionTypes';

export default function cabinUnholdReducer(store = { data: {}, error: {} }, action) {
  switch (action.type) {
    case SUMMARY_UNHOLD_CABIN_SUCCESS:
      return { ...store, data: action.payload, error: {} };
    case SUMMARY_UNHOLD_CABIN_ERROR:
      return { ...store, data: {}, error: action.payload };
    default:
      return store;
  }
}
