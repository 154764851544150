const SAVE_PREV_PATH = 'SAVE_PREV_PATH';
const SAVE_PREV_SEARCH_PARAMS = 'SAVE_PREV_SEARCH_PARAMS';

const defaultState = {
  prevPath: null,
  prevSearchParams: null,
};

export const savePrevPath = (payload) => ({ payload, type: SAVE_PREV_PATH });
export const savePrevSearchParams = (payload) => ({ payload, type: SAVE_PREV_SEARCH_PARAMS });

const pageReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case SAVE_PREV_PATH:
      return { ...state, error: {}, prevPath: action.payload };
    case SAVE_PREV_SEARCH_PARAMS:
      return { ...state, error: {}, prevSearchParams: action.payload };
    default:
      return state;
  }
};

export default pageReducer;
