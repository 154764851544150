import builders from './builders';
import modifiers from './modifiers';

// eventCategory: prefix-page|module|flyout
const getEventCategory = ({ flyouts, module, prefix, urlMap, urlPath }) => {
  const url = prepareUrl(urlPath);
  const urlSubParts = getUrlPathSeparated(url);
  const modulePart = builders.buildModule(module);
  if (!urlSubParts?.length) {
    return modulePart;
  }

  const mappedUrls = modifiers.mapUrls(urlSubParts, urlMap);
  const modifiedUrlSubParts = modifiers.noDuplicateWithModuleName(mappedUrls, module);
  const onlyLastUrlPath = modifiers.takeSignificantSubPath(modifiedUrlSubParts);
  const pagePart = builders.buildPage(prefix, onlyLastUrlPath);

  const flyoutPart = builders.buildFlyouts(flyouts);

  const eventCategory = builders.buildEventCategory(pagePart, modulePart, flyoutPart);
  return eventCategory;
};

const prepareUrl = (url) => {
  if (!url) {
    return '';
  }

  let preparedUrl = url;
  if (!Array.isArray(preparedUrl)) {
    preparedUrl = preparedUrl?.includes('|') ? preparedUrl.split('|') : preparedUrl?.toString();
  } else {
    preparedUrl = url.at(0);
  }
  return preparedUrl;
};

const getUrlPathSeparated = (url) => {
  const slashedPath = url || window.location.pathname;
  const urlPathSeparated = slashedPath?.split('/')?.filter(Boolean);
  return urlPathSeparated;
};

export default { getEventCategory, getUrlPathSeparated };
