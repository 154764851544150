import isEmpty from 'lodash/isEmpty';

import { selectShownSailing } from '@/ducks/pages/chooseVoyage/selectors/sailings';
import { getAllFilters } from '@/helpers/util/sailing';

import constants from '../../../constants';
import tagmanager from '../../../index';
import builders from '../../builders';
import {
  generateBaseCabinFields,
  generateCabinProductsFromSailings,
  generateECommerce,
  generatePaymentSummary,
  generatePurchaseActionField,
  trackEcommerce,
} from './general';
import { transformProducts } from './transform';

const { EVENTS, MEASURES, VV_EVENTS_ROOT_PREFIX } = constants;
const { buildLabel } = builders;

export const measuringProductImpressions =
  ({ labels, list, sailings }) =>
  (dispatch, getState) => {
    const state = getState();
    const { accessible, currencyCode, isMNVV, sailors } = getAllFilters(state);

    const { label: generatedLabel } = generateFunnels({ labels, list });

    const sailingsOrCurrent = sailings?.length ? sailings : [selectShownSailing(state)];
    const impressions = generateCabinProductsFromSailings({
      list,
      sailings: sailingsOrCurrent,
      state,
    });
    const transformedImpressions = transformProducts(impressions);

    trackEcommerce({
      action: constants.MEASURES.IMPRESSIONS,
      currencyCode,
      ecommerce: {
        impressions: transformedImpressions,
      },
      isAccessible: accessible,
      isMNVV,
      label: generatedLabel,
      sailorCount: sailors,
    });
  };

export const measuringProductClicks =
  ({ flyout = null, labels = [], list, sailing }) =>
  (dispatch, getState) => {
    const state = getState();
    const { accessible, currencyCode, isMNVV, sailors } = getAllFilters(state);

    const { label: generatedLabel, list: generatedList } = generateFunnels({
      flyout,
      labels,
      list,
    });

    const products = generateCabinProductsFromSailings({
      list,
      sailings: [sailing],
      state,
    });
    const transformedProducts = transformProducts(products);

    trackEcommerce({
      action: MEASURES.PRODUCT_CLICKS,
      currencyCode,
      ecommerce: {
        click: {
          actionField: { list: generatedList },
          products: transformedProducts,
        },
      },
      isAccessible: accessible,
      isMNVV,
      label: generatedLabel,
      sailorCount: sailors,
    });
  };

export const measuringPromotionImpressions = (cabins) => (dispatch, getState) => {
  const state = getState();
  const { accessible, currencyCode, isMNVV, sailors } = getAllFilters(state);

  const sailing = selectShownSailing(state);
  const promotions = cabins.map((cabin) =>
    generateBaseCabinFields({
      cabin,
      sailing,
    }),
  );
  const transformedPromotions = promotions.map(transformProducts);

  trackEcommerce({
    action: MEASURES.PROMOTION_VIEWS,
    currencyCode,
    ecommerce: {
      promoView: {
        promotions: transformedPromotions,
      },
    },
    isAccessible: accessible,
    isMNVV,
    sailorCount: sailors,
  });
};

export const measuringPromotionClick =
  ([cabin]) =>
  (dispatch, getState) => {
    const state = getState();
    const { accessible, currencyCode, isMNVV, sailors } = getAllFilters(state);

    const sailing = selectShownSailing(state);
    const promotions = [
      generateBaseCabinFields({
        cabin,
        sailing,
      }),
    ];
    const transformedPromotions = transformProducts(promotions);

    trackEcommerce({
      action: MEASURES.PROMOTION_CLICKS,
      currencyCode,
      ecommerce: {
        promoClick: {
          promotions: transformedPromotions,
        },
      },
      isAccessible: accessible,
      isMNVV,
      sailorCount: sailors,
    });
  };

export const measuringDetailsImpressions =
  ({ isUpsellEnabled, list, sailing, subMetaCategory }) =>
  () =>
  (dispatch, getState) => {
    const state = getState();
    const { accessible, currencyCode, isMNVV, sailors } = getAllFilters(state);

    const { flyout, labels: labelsByFilters } = generateLabelsByFilters({
      isUpsell: isUpsellEnabled,
    });

    const { label: generatedLabel, list: generatedList } = generateFunnels({
      flyout,
      labels: labelsByFilters,
      list,
    });

    const cabinTypes = (subMetaCategory.cabinTypes || []).length === 0 ? [subMetaCategory] : subMetaCategory.cabinTypes;
    const detail = generateECommerce({
      list: generatedList,
      sailing,
      state,
      subMetas: cabinTypes,
    });
    const transformedDetail = transformProducts(detail);

    trackEcommerce({
      action: MEASURES.PRODUCT_DETAILS,
      currencyCode,
      ecommerce: {
        detail: {
          products: transformedDetail,
        },
      },
      isAccessible: accessible,
      isMNVV,
      isUpsellApplied: isUpsellEnabled,
      isUpsellEnabled,

      label: generatedLabel,
      sailorCount: sailors,
    });
  };

export const measuringDetailsClick =
  ({ isUpsellApplied, labels = [], list, sailing, subMetas }) =>
  (dispatch, getState) => {
    const state = getState();
    const { accessible, currencyCode, isMNVV, sailors } = getAllFilters(state);

    const isUpsellEnabled = isUpsellApplied !== undefined;
    const { flyout, labels: labelsByFilters } = generateLabelsByFilters({
      isUpsell: isUpsellEnabled,
    });

    const { label: generatedLabel, list: generatedList } = generateFunnels({
      flyout,
      labels: [...labels, ...labelsByFilters],
      list,
    });

    const detail = generateECommerce({
      list: generatedList,
      sailing,
      state,
      subMetas,
    });
    const transformedDetail = transformProducts(detail);

    trackEcommerce({
      action: MEASURES.ADD_TO_CART,
      currencyCode,
      ecommerce: {
        add: {
          products: transformedDetail,
        },
      },
      isAccessible: accessible,
      isMNVV,
      isUpsellApplied,
      isUpsellEnabled,
      label: generatedLabel,
      sailorCount: sailors,
    });
  };

export const measuringCheckoutStep =
  ({ action, list, sailing, step, subMetas }) =>
  (dispatch, getState) => {
    const state = getState();
    const { accessible, currencyCode, isMNVV, isUpsellApplied, isUpsellEnabled, sailors } = getAllFilters(state);

    const { flyout, labels: labelsByFilters } = generateLabelsByFilters({
      isUpsell: isUpsellEnabled,
    });
    const { label: generatedLabel, list: generatedList } = generateFunnels({
      flyout,
      labels: labelsByFilters,
      list,
    });

    const detail = generateECommerce({
      list: generatedList,
      sailing,
      state,
      subMetas,
    });
    const transformedDetail = transformProducts(detail);

    trackEcommerce({
      action,
      currencyCode,
      ecommerce: {
        checkout: {
          actionField: {
            step,
          },
          products: transformedDetail,
        },
      },
      isAccessible: accessible,
      isMNVV,
      isUpsellApplied,
      isUpsellEnabled,

      label: generatedLabel,
      sailorCount: sailors,
    });
  };

export const measuringPurchases =
  ({ booking, cabinInvoice, list, paymentSignature, sailing, subMetas }) =>
  (dispatch, getState) => {
    const state = getState();

    const { isMNVV, isUpsellEnabled } = getAllFilters(state);

    const { flyout, labels: labelsByFilters } = generateLabelsByFilters({
      isUpsell: isUpsellEnabled,
    });
    const { label: generatedLabel, list: generatedList } = generateFunnels({
      flyout,
      labels: labelsByFilters,
      list,
    });

    const isHoldZeroPay = isEmpty(paymentSignature);

    const actionField = generatePurchaseActionField({
      booking,
      cabinInvoice,
      paymentSignature,
      state,
      ...(isHoldZeroPay ? { revenue: '0' } : {}),
    });

    const detail = generateECommerce({
      list: generatedList,
      sailing,
      state,
      subMetas,
      ...(isHoldZeroPay ? { price: '0', quantity: 0 } : { quantity: 1 }),
    });
    const transformedDetail = transformProducts(detail);

    const summary = generatePaymentSummary({
      booking,
      cabinInvoice,
      paymentSignature,
      sailing,
      state,
    });

    trackEcommerce({
      action: MEASURES.PURCHASES,
      ecommerce: {
        purchase: {
          actionField,
          products: transformedDetail,
        },
      },
      event: EVENTS.GA_ECOMMERCE,
      isMNVV,
      label: generatedLabel,

      ...summary,
    });
  };

const generateLabelsByFilters = (filters) => {
  const { isUpsell } = filters;

  if (isUpsell) {
    return {
      flyout: constants.FLYOUTS.UPSELL,
      labels: [tagmanager.trackerConstants.EVENT_LABELS.UPSELL_LIGHTBOX],
    };
  }

  return {
    flyout: null,
    labels: [],
  };
};

const generateFunnels = ({ flyout, labels, list }) => {
  const buildedLabel = generateLabel(labels);
  const buildedList = generateList(list, flyout);

  return {
    label: buildedLabel,
    list: buildedList,
  };
};

const generateLabel = (labels = []) => {
  // buildLabel will to kebabcase VV prefix if pass it
  const labelAfterPrefix = buildLabel(labels);
  return `${VV_EVENTS_ROOT_PREFIX}${labelAfterPrefix?.length ? `|${labelAfterPrefix}` : ''}`;
};

const generateList = (list, flyout) => buildLabel([list, flyout]);
